<app-header></app-header>
<article class="main">
    <section class="page-header">
        <div class="container">
            <!-- <span class="page-subtitle">indulge your adventurous spirit</span> -->
            <h1 class="page-title">Facilities</h1>
        </div>
    </section>
    <article class="facilit-wrap">
        <section class="facilit" *ngFor="let cnt of content; let i=index">
            <div class="container grid_base_a" *ngIf="i%2==0">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="facilit__content">
                            <h2 class="facilit__title">{{cnt.title}}</h2>
                            <h6 class="facilit__sub">{{cnt.main}}</h6>
                            <p class="facilit__text">{{cnt.description}}</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="facilit__slider">
                            <owl-carousel-o [options]="multiOptions">
                                <ng-template carouselSlide *ngFor="let i of cnt.image">
                                  <i class="image"><img src="{{i}}" alt="pool"></i>
                                </ng-template>
                              </owl-carousel-o>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container grid_base_b" *ngIf="i%2 !=0">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="facilit__slider">
                            <owl-carousel-o [options]="multiOptions">
                                <ng-template carouselSlide *ngFor="let i of cnt.image">
                                  <i class="image"><img src="{{i}}" alt="pool"></i>
                                </ng-template>
                              </owl-carousel-o>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="facilit__content right" >
                            <h2 class="facilit__title">{{cnt.title}}</h2>
                            <h6 class="facilit__sub">{{cnt.main}}</h6>
                            <p class="facilit__text">{{cnt.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="niambur_sec">
            <div class="container grid_base_a" >
                <div class="row">

                    <div class="col-lg-6">
                        <div class="facilit__slider">
                            <owl-carousel-o [options]="multiOptions">
                                <ng-template carouselSlide >
                                <i class="image"><img src="assets/img/River-view-.webp" alt="pool"></i>
                                </ng-template>
                             
                                
                            </owl-carousel-o>
                        </div>  
                    </div>

                    <div class="col-lg-6">
                        <div class="facilit__content right" >
                            <h2 class="facilit__title">Tribal Budha</h2>
                            <h6 class="facilit__sub">The millions of encounters our river water have shared with memories of each of our guests is countless..</h6>
                            <p class="facilit__text">When we look beyond materialistic creations, we are able to behold who we truly are and that's why we would love to welcome you to our Tribal Budha! Read, sing, relax, write, observe, paint, you could do anything here, you could joyfully immerse in the beauty of the flowing river, you could plug in your headset and wander through emotions of imaginations, it's a space for everyone to meet your inner needs! 
                                We have wooden seating and vibrant <span><a class="ex-link" href="https://en.wikipedia.org/wiki/Hammock" target="_blank">Hammocks</a></span> so that you can sit back and enjoy the sights of nature. </p>
                        </div>
                    </div>

                    
                
                </div>
            </div>
        </section>
    </article>

</article>
<app-footer></app-footer>