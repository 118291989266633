import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(public api: ApiService) { }
  getRoomPrice() {
    let endpoint = "roomprice";
    return this.api.get(endpoint)
    .pipe(map(resp=>resp.json()))
  }
}
