import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  content = [
    {
      image: ['assets/img/Farm1.webp', 'assets/img/Farm2--.webp'],
      title: 'Explore Farm',
      id:'farm',
      main: "The soil is the greatest bond humans hold and without proper care it could affect our livelihood, thus Visiting Farms enlightens us the prize of cultivation. It's not just about nurturing crops, but perfecting it until it's resourceful. Visit a Farm with us and feel the passion of a farmer's heart!",
      description: "Learning the essence of farming is one of the joys of living in this pristine planet, and it could be a contribution to our community too. Delight yourself by exploring nutmeg cultivation, plantain farm, coffee plantation, black pepper farm and many more! These plantations not only conserve water, soil and wildlife but also keeps up all the living networks that braces our community. Farming is not just being organic, it's merely another expression of art, and be a part of this art!"
    },
    {
      image: ['assets/img/Offroad-one-.webp', 'assets/img/Offroad-two--.webp'],
      title: 'Wheels of Wilderness! ',
      id:'off-road',
      main: "As the afternoon sun gleams, we start our Off - Road drive. You're not by yourself in this journey, long leaves will be patting you as you cross roads while splashing mud and crushing stones. Hold your grip and behold your breath, as you still have not witnessed the immense beauty that awaits you. Your destined location do not have a shortcut, which creates this bumpy ride more thrilling.",
      description: "Ups and downs is what makes us strong in life, so do this drive. As you travel through deep woods, with branches against the sky, the unexpected bends of this road will never fail to satisfy your inner desire. The mistiness of the atmosphere creates a warmth in you that kicks in your energy for this tough and rough ride. Our safe vehicle and professional driver will carry you to your destination while ensuring your safety too. The wildness of this ride takes you to a sunset point where you can recline. The road to fulfilment is never a straight one, hence, Off - Road drives are divine. As your Off - Road ride ends, those fresh traces of wheels will always remember you."
    },
    {
      image: ['assets/img/hiking-1-.webp', 'assets/img/Hiking2.webp', 'assets/img/Hiking2-.webp'],
      title: 'A Hike that Brings You Down to Earth',
      id:'hike',
      main: "The freshness of morning air is irreplaceable, that's why our Hike is set on early mornings. As misty clouds wander, get ready to push the limit of your body until the afternoon warmth hugs you. Hike trails always enable us to notice the tiny aspects of nature that we usually miss out. ",
      description: "A professional will guide you as well as provide you the required gear, and it will be a Hike that won't make you stop walking. Hiking is like finding a treasure, it will always leave a golden glow on your heart. As you Hike, wild flowers of mountains will greet you while birds chirp and clouds drift. This is not just about climbing hills, it's about feeling unbounded and shedding distractions because, to feel free is a blessing. Hiking aids in opening oneself to unexpected experiences and facing flairs of nature. It's not always about the top of the mountain, but sides of mountain's too carry stories, hence we want you to feel that. Viewing a mountain from a distance can only give you an idea of it's shape and surroundings, as you move closer, you realise you have a lot more to see. The world is more visible to those who travel on foot, so come experience a beautiful Hike with Mayaahi."
    },
    {
      image: ['assets/img/Fishing-one--.webp','assets/img/Fishing-couples-.webp','assets/img/Fishing-2.webp', ],
      title: 'Fishing',
      id:'fish',
      main: "Grab your Fishing pole and let this experience enthrall you! As you watch in silence the beauty and grandeur of our river fishes, bait your hook and extend your pole! Stand still and focus until one of them gets close! The charm of Fishing lies in the grade of patience thus transfiguring this activity into a form of meditation.",
      description: "Fishing not only elates you as a leisure sport, but also alleviates your mind & body. The wait to sense the gentle touch on your line and the wait for the fish to nibble your bait is the most electrified instance in this activity. The excitement you experience when your Fishing bobber shakes, and you raise your pole instantly is delighting. As the majestic birds soar high up in the sky all you need is the endeavor to hook up a fish deep in the shadowy water!"
    },
    // {
    //   image: ['assets/img/nilambur-one.jpg', 'assets/img/nilambur-two.jpg', 'assets/img/nilambur-three.jpg', 'assets/img/nilambur-four.jpg'],
    //   title: 'Touring Nilambur',
    //   id:'nila',
    //   main: "No trip is interesting if you didn't get to see what you have always wanted to. Close to the Nilgiris on the banks of Chaliyar, Nilambur is a town that must be experienced! This most photographed city of Malappuram, beholds many iconic trademarks of Kerala.",
    //   description: "Sightsee with Mayaahi as we take in the landmarks of this Neighborhood, hemmed by teak trees on either side. On this sightseeing journey of Nilambur which is surrounded by dense forest of mixed vegetation and rivers, it would be a miss if you didn't visit the world's first Teak Museum. Conolly's Plot, the first man made teak plantation in the world is also a prominent destination and the list continues. Nilambur is not just about historic sites and serene beauty, local dish such as the lip - smacking beef curry and crisp porottas are also a delight to your heart!"
    // },
    {
      image: ['assets/img/Food-.webp'],
      title: 'Learn a Dish',
      id:'dish',
      main: "Savoury or sweet, our taste buds never tire! Enhance your stay at Mayaahi by mastering a new dish! Our head chef would love to guide you while you take your baby steps on learning Mayaahi's iconic dish! A dash of passion and interest moulds any style of cooking alluring! On this voyage of getting to know more about a dish you also get to explore our natural ingredients , and it's roots as any meal infused with organic elements makes you healthy as well as joyful!",
      description: " As you commence cooking from along with our chef, the novel refreshing scent is just enough to make your mouth water and that shapes this learning journey a pinch more exhilarating! Witness our chef's astounding free style cooking and get enthralled to create your own genre of this novel dish!  At Mayaahi you would be experiencing food that dissolves into a mélange of delicious flavours not just by tasting but by creating too! Own your dish with Mayaahi! "
    },
  ]

  constructor(public route:ActivatedRoute) {
   this.moveToHash()
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);

  }
  ngAfterViewInit() {

  }




  multiOptions: OwlOptions = {
    items: 1,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 0,
    dots: true,
    nav: false,
    navSpeed: 700,
  }


  moveToHash(){
    try {
      let that=this
      setTimeout(()=>{
        that.route.fragment.subscribe(fragment => {
          console.log(fragment)
          if(fragment){
            let links:any = document.querySelector('#' + fragment)
          links.scrollIntoView();
          }
        });
      },1000)
    } catch (e) {}
  }
}
