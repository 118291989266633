<app-header></app-header>
<article class="main">
    <section class="page-header">
        <div class="container">
            <!-- <span class="page-subtitle">indulge your adventurous spirit</span> -->
            <h1 class="page-title">Neighbourhood</h1>
        </div>
    </section>
    <article class="facilit-wrap">
        <section class="niambur_sec">
            <div class="container grid_base_a" >
                <div class="row">
                 

                    <div class="col-lg-6">
                        <div class="facilit__slider">
                            <owl-carousel-o [options]="multiOptions">
                                <ng-template carouselSlide >
                                <i class="image"><img src="assets/img/Waterfalls-2-.webp" alt="pool"></i>
                                </ng-template>
                            </owl-carousel-o>
                        </div>  
                    </div>
                    <div class="col-lg-6">
                        <div class="facilit__content right" >
                            <h2 class="facilit__title">Adyanpara Waterfall -  <span class="kms"><img src="assets/img/car.png" width="50"> 16KM</span> </h2>
                            <h6 class="facilit__sub">Run wild and free like Nilambur's Adyanpara Waterfall! The rhythm of this waterfall is a <span><a class="exr-link" href=" https://en.wikipedia.org/wiki/Lullaby" target="_blank">Lullaby</a></span> for your heart</h6>
                            <p class="facilit__text">This eminent site belongs to the village of Kurumbalangode. Adyanpara Waterfall is a delight, principally during monsoon. This waterfall is not only presented by clear pristine water, it's also adjoined by green lushly forest. The water is even reckoned to have medicinal properties. The water descends over rocks, making Adyanpara Waterfall more notable for trekking. You might even encounter migratory birds as this is one of their treasured hubs!
                                You can experience the Adyanpara Waterfall through <span><a class="ex-link" routerLinkActive="active" routerLink="/activity">Mayaahi</a></span>. </p>
                        </div>
                    </div>
                
                </div>
            </div>
        </section>
        <section class="facilit" *ngFor="let cnt of content; let i=index">
            <div class="container grid_base_a" *ngIf="i%2==0">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="facilit__content">
                            <h2 class="facilit__title">{{cnt.title}} -  <span class="kms"><img src="assets/img/car.png" width="50"> {{cnt.km}}KM</span> </h2>
                            <h6 class="facilit__sub">{{cnt.main}}</h6>
                            <p class="facilit__text">{{cnt.description}}</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="facilit__slider">
                            <owl-carousel-o [options]="multiOptions">
                                <ng-template carouselSlide *ngFor="let i of cnt.image">
                                  <i class="image"><img src="{{i}}" alt="pool"></i>
                                </ng-template>
                              </owl-carousel-o>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container grid_base_b" *ngIf="i%2 !=0">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="facilit__slider">
                            <owl-carousel-o [options]="multiOptions">
                                <ng-template carouselSlide *ngFor="let i of cnt.image">
                                  <i class="image"><img src="{{i}}" alt="pool"></i>
                                </ng-template>
                              </owl-carousel-o>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="facilit__content right" >
                            <h2 class="facilit__title">{{cnt.title}} -  <span class="kms"><img src="assets/img/car.png" width="50"> {{cnt.km}}KM</span> </h2>
                            <h6 class="facilit__sub">{{cnt.main}}</h6>
                            <p class="facilit__text">{{cnt.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>

</article>

<app-footer></app-footer>