import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BlogsService } from 'src/app/providers/blogs.service';
import { CommonService } from 'src/app/providers/common.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {
  blogs: any;
  blog_id: any;
  blogDetail: any = []
  is_skelton:boolean=false
  pageId:any;

  constructor(public common: CommonService, public route: ActivatedRoute,public blog:BlogsService) {
    this.route.params.subscribe(val => {
      this.blog_id = val['id']
      console.log(this.blog_id)
    
    })

  }


  ngOnInit(): void {
    this.getBlogView();
    this.getBlogs()
  }



  changeData(id:any){
    console.log(id)
    this.blog_id=id
    this.blogDetail=[]
    this.getBlogView()
    this.getBlogs()
    window.scroll(0,0)
  }

  getBlogView(){
    this.blog.getAllBlogsById(this.blog_id).subscribe((resp:any)=>{
      if(resp.success == true){
       this.blogDetail=resp.data
      }
    })
  }

  getBlogs(){
    this.blog.getAllBlogs().subscribe((resp:any) => {
      if (resp.success == true) {
        this.blogs = resp.data?.data
      }
    })
  }

  
}
