<app-header></app-header>
<section class="page-header">
    <div class="container">
        <!-- <span class="page-subtitle">indulge your adventurous spirit</span> -->
        <h1 class="page-title">Blogs Detail</h1>
    </div>
</section>
<section id="Journals" style="display: block;">
    <div class="container mx-auto">
        <div class="page-title">
            <h1></h1>
            <p></p>
        </div>
        <div class="row">
            <div class="col-md-8 col-sm-12 main-Journals blog-details">
                <img src="{{blogDetail.image_url}}" alt="">
                <h2 class="main_title">{{blogDetail.title}}</h2>
                <span class="blog_para">{{blogDetail.created_at | date}}</span>
                <div class="blog_content" >
                    <p [innerHTML]="(blogDetail.description) "></p>
                </div>
            </div>

            <div class="col-md-4 col-sm-12 more-Journals ">
                <h5 class="related_title">Related Blogs</h5>
                <div class="Journals-item d-flex mb-4 related-blog" *ngFor="let bl of blogs; index as i" (click)="changeData(bl.id)">
                    <ng-container *ngIf="bl.id != blog_id">
                    <img src="{{bl.image_url}}"  alt="">
                    <div class="">
                        <a >
                            <h5 class="blog_title">{{bl.title}}</h5>
                        </a>
                        <p class="blog_para">{{bl.created_at | date :'dd MMM yyyy'}}</p>
                    </div>
                    </ng-container>

                </div>
                <!-- <div class="Journals-item d-flex mb-4 related-blog">
                    <img src="assets/img/Blog3.webp" alt="">
                    <div class="">
                        <a routerLinkActive="active" routerLink="/blog-detail-two">
                            <h5 class="blog_title">Enhancing the Guest Experience: Boutique Hotel Accommodations at
                                Mayaahi</h5>
                        </a>
                        <p class="blog_para">15-04-2023</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>