<app-header></app-header>

    <section class="page-header">
        <div class="container">
            <!-- <span class="page-subtitle">indulge your adventurous spirit</span> -->
            <h1 class="page-title">Blogs</h1>
        </div>
    </section>
    <section class="blog-list-page ">
        <div class="container">
            <h2 class="text-center our_title">Our Latest Blogs</h2>
    
            <div class="row">
                <!-- <div class="col-md-4 blog-detail" *ngFor="let team of blogList | paginate: { itemsPerPage: 6, currentPage: p }">
                    <a   [routerLink]="['/blog-detail/',team.id]">
                    <div class="blog-list-item">
                        <img src="{{team.image_url}}" class="img-fluid">
                        <h5>{{team.title}} </h5>
                        <span>{{team.created_at | date :'dd MMM yyyy'}}</span>
                    </div>
                    </a>
                </div> -->
                <div class="col-lg-4 mt-5 blog-detail" *ngFor="let data of blogList" >
                    <a routerLinkActive="active" [routerLink]="['/blogs', data?.id]">
                        <div class="blog-list-item">
                            <img src={{data?.image_url}} class="img-fluid">
                            <h5>{{data.title}}</h5>
                            <span>{{data?.created_at | date}}</span>
                        </div>
                    </a>
                </div>
            </div>
    
        </div>
    </section>

    <!-- <div class="pagination-area">
        <div class="container">
            <pagination-controls class="my-pagination" (pageChange)="p = $event" class="float-right"></pagination-controls>
        </div>
    </div>
    -->


<app-footer></app-footer>