import { Component, OnInit } from '@angular/core';
import { TestimonialsService } from 'src/app/providers/testimonials.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-testimonial-detail',
  templateUrl: './testimonial-detail.component.html',
  styleUrls: ['./testimonial-detail.component.scss']
})
export class TestimonialDetailComponent implements OnInit {

  public testimonials:any = [];

  constructor(
    private testimonialsService:TestimonialsService,

  ) { }

  ngOnInit() {
    this.getTestimonialsList()
  }

  async getTestimonialsList() {
    await this.testimonialsService.getAllTestimonials().subscribe(
      (response) => {
        this.testimonials = response.data.data;
        console.log("test",this.testimonials)
      },
      (error) => {
        console.error(error); 
      }
    );
  }
  

  showFullTestimonial(event: Event, testimonial: any) {
    event.preventDefault(); // Prevent the default anchor behavior
    testimonial.showFullContent = true;
  }

  showLessTestimonial(event: Event, testimonial: any) {
    event.preventDefault(); // Prevent the default anchor behavior
    testimonial.showFullContent = false;
  }

}
