import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { CommonService } from 'src/app/providers/common.service';
import { EventPublishService } from 'src/app/providers/event-publish.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery';
import { RoomService } from 'src/app/providers/room.service';
import { DateAdapter } from '@angular/material/core';


@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})


export class RoomComponent implements OnInit {

  minDate: any;
  minDate2: any;
  imgPosition: any = 0;

  adults = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' }
  ];

  children = [
    { value: '0', viewValue: '0' },
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '2', viewValue: '3' }
  ];

  nights = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '2', viewValue: '3' }
  ];
  multiOptions: OwlOptions = {
    items: 1,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 0,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    nav: false
  }

  adultValue: any;
  childValue: any;
  nightValue: any;
  bookingForm: any = FormGroup
  submitted: boolean = false
  roomPrice: any = [];


  constructor(public formBuilder: FormBuilder, public dailog: MatDialog, public common: CommonService, public events: EventPublishService,
    public datePipe: DatePipe, public room: RoomService,private dateAdapter: DateAdapter<Date>) {
      this.dateAdapter.setLocale('en-GB');
    }

  ngOnInit(): void {
    this.getPrice();
    window.scroll(0, 0);
    this.bookingForm = this.formBuilder.group({
      checkIn: ['', Validators.required],
      checkout: ['', Validators.required],
      adults: ['', Validators.required],
      children: [''],
      nights: ['', Validators.required]
    })

    this.bookingForm.get('checkIn').patchValue(this.common.bookingData.check_in);
    this.bookingForm.get('checkout').patchValue(this.common.bookingData.check_out);
    this.bookingForm.get('adults').patchValue(this.common.bookingData.adults);
    this.bookingForm.get('children').patchValue(this.common.bookingData.childrens);
    this.calculateNights()

    var y: any = this.datePipe.transform(Date(), 'yyyy')
    var m: any = this.datePipe.transform(Date(), 'M')
    var d: any = this.datePipe.transform(Date(), 'dd')
    this.minDate = new Date(y, m - 1, d);
    console.log(this.minDate)

    $(".clearForm").click(function () {
      $(".reset").trigger("click");
    });

    $(".room_image_small img").on('click mouseover', function (e) {
      console.log($(this))
      let img: any = $(this).attr("src");
      let position: number = $(this).index();
      // $(".room_image_inner img").data("id",position);
      let child_size: any = $(this).width();
      let div: any = $(this).parents(".room_image_small");
      $(this).parents(".room_image_flx").find(".room_image_inner img").attr("src", img);
      console.log(div)
      div.scrollLeft((position * (child_size + 32)) / 2);
    })
    $(".close_mdl").click(function (e) {
      $(".modl_main").hide()
    })

    $(".room_image_inner img").click(function (e) {
      $(".modl_main").show()
      let img: any = $(this).attr("src");
      $(".mdl_big_img").attr("src", img);
      $(".mdn_child_image").html($(this).parents(".room_image_flx").find(".room_image_small").html())
    })

    $(document).on("click", ".mdn_child_image img", function () {
      let img: any = $(this).attr("src");
      $(".mdl_big_img").attr("src", img);
      let position: number = $(this).index();
      // $(".room_image_inner img").data("id",position);
      let child_size: any = $(this).width();
      let div: any = $(this).parents(".mdn_child_image");
      div.scrollLeft((position * (child_size + 32)) / 2);
    });


    $(document).on("click", ".prev_mdl", function (e) {
      let src = $(".mdl_big_img").attr("src");
      $(".mdn_child_image img").each(function () {
        if ($(this).attr("src") == src) {
          let position_inner: number = $(this).index();
          let nextIMG: any = $(".mdn_child_image").children().eq(position_inner + 1).attr("src");
          $(".mdl_big_img").attr("src", nextIMG);
        }
      });
    });

    $(document).on("click", ".next_mdl", function (e) {
      let src = $(".mdl_big_img").attr("src");
      $(".mdn_child_image img").each(function () {
        if ($(this).attr("src") == src) {
          let position_inner: number = $(this).index();
          let nextIMG: any = $(".mdn_child_image").children().eq(position_inner - 1).attr("src");
          $(".mdl_big_img").attr("src", nextIMG);
        }
      });
    });




  }



  selectCheckInDate(event: any) {
    this.bookingForm.get('checkIn').setValue(event.value);
    console.log(this.bookingForm.value.checkIn)
    var y: any = this.datePipe.transform(this.bookingForm.value.checkIn, 'yyyy')
    var m: any = this.datePipe.transform(this.bookingForm.value.checkIn, 'M')
    var d: any = this.datePipe.transform(this.bookingForm.value.checkIn, 'dd')
    this.minDate2 = new Date(y, m - 1, d);

  }

  selectCheckOutDate(event: any) {
    this.bookingForm.get('checkout').setValue(event.value);
    console.log(this.bookingForm.value.checkout)
    this.calculateNights()
  }

  calculateNights() {
    var nights = moment(this.bookingForm.value.checkout).diff(moment(this.bookingForm.value.checkIn), 'days')
    this.bookingForm.get('nights').setValue(nights);
  }

  get avf() { return this.bookingForm.controls; }


  MakeBooking(roomId: any) {
    this.submitted = true
    if (this.bookingForm.invalid) {
      window.scroll(0, 0);
      return
    }
    if (this.bookingForm.valid) {
      this.common.bookingData = {
        room_id: roomId,
        check_in: this.avf.checkIn.value,
        check_out: this.avf.checkout.value,
        childrens: this.avf.children.value,
        adults: this.avf.adults.value,
        nights: this.avf.nights.value
      }
      console.log(this.common.bookingData)
      this.common.bookNow = true
    }
    this.events.publishSomeData(this.common.FORM_DATA_UPDATED)

  }

  getPrice() {
    this.room.getRoomPrice().subscribe((resp) => {
      if (resp.success == true) {
        this.roomPrice = resp.data;
        console.log(this.roomPrice)
      }
    })
  }

}
