import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './views/about/about.component';
import { ActivityComponent } from './views/activity/activity.component';
import { BlogsComponent } from './views/blogs/blogs.component';
import { BooknowComponent } from './views/booknow/booknow.component';
import { ContactComponent } from './views/contact/contact.component';
import { FacilityComponent } from './views/facility/facility.component';
import { HomeComponent } from './views/home/home.component';
import { NeighbourhoodComponent } from './views/neighbourhood/neighbourhood.component';
import { RoomComponent } from './views/room/room.component';
import { ThankYouComponent } from './views/thank-you/thank-you.component';
import { NotFoundComponentComponent } from './views/not-found-component/not-found-component.component';
import { BlogDetailComponent } from './views/blog-detail/blog-detail.component';
import { BlogDetailOneComponent } from './views/blog-detail-one/blog-detail-one.component';
import { BlogDetailTwoComponent } from './views/blog-detail-two/blog-detail-two.component';
import { TestimonialDetailComponent } from './views/testimonial-detail/testimonial-detail.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full',
  // },
  {
    path: '',component:HomeComponent,
    
    data: {
      seo: {
        title: 'Resorts in Kakkadampoyil: Mayaahi Boutique Stay',
        metaTags: [
          { name: 'description', content: 'Resorts in Kakkadampoyil:Discover luxury at Mayaahi Boutique Stay. Elegant accommodations and serene beauty for a perfect tranquil retreat.' },
          { name:"keywords", content:"Mayaahi, Boutique Stay, Rain Forest Resort, Kakkadampoyil, Nilambur, nature, resort, best resort, Kerala, hotel" },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@Mayaahi' },
          { name: 'twitter:title', content: 'The Sky, The Hills & Mayaahi.' },
          { name: 'twitter:description', content: 'Mayaahi is an experience brought together by the earth, water, sky and a bunch of enlightened beings. Our Boutique Stays sky sails along with a bundle of our guests tales.' },
          { name: 'twitter:image', content: 'https://www.mayaahi.com/assets/img/budha.webp' },
          { property: 'og:title', content: 'Mayaahi'},
          { property: 'og:site_name', content: 'Mayaahi' },
          { property: 'og:url', content: 'https://www.mayaahi.com/' },
          { property: 'og:description', content: 'Mayaahi is an experience brought together by the earth, water, sky and a bunch of enlightened beings. Our Boutique Stays sky sails along with a bundle of our guests tales. When every flower returns to sleep , Mayaahi beams like a lantern.The caress of our Boutique Stays refreshing air will kindle your heart' },
          { property: 'og:type', content: 'website' },
          { property: 'og:image', content: '' },{ property: 'og:image', content: '' },
        ]
      }
    }
  },
  {
    path: 'best-resort-kakkadampoyil',component:AboutComponent,
    data: {
      seo: {
        title: 'Best  Resorts in Kakkadampoyil, Nilambur, Malappuram, Calicut',
        metaTags: [
          { name: 'description', content: 'Best premium forest resorts in Kakkadampoyil, Nilambur, Malappuram, Calicut. check our contact number to book your stay at low price in Kakkadampoyil.' },
          { property: 'og:title', content: 'Best  Resorts in Kakkadampoyil, Nilambur, Malappuram, Calicut'},
          { property: 'og:url', content: 'https://www.mayaahi.com/best-resort-kakkadampoyil' },
        ]
      }
    }
  },
  {
    path: 'rooms-homestays',component:RoomComponent,
    data: {
      seo: {
        title: 'Home stay in Kakkadampoyil Nilambur Malappuram Calicut',
        metaTags: [
          { name: 'description', content: 'Mayaahi,s  home stay in Kakkadampoyil Nilambur Malappuram Calicut provides accommodation with a private pool room.'},
          { property: 'og:title', content: 'Home stay in Kakkadampoyil Nilambur Malappuram Calicut'},
          { property: 'og:url', content: 'https://www.mayaahi.com/rooms-homestays' },
        ]
      }
    }
  },
  {
    path: 'tourist-places-kakkadampoyil',component:NeighbourhoodComponent,
    data: {
      seo: {
        title: 'Kakkadampoyil Tourist Places',
        metaTags: [
          { name: 'description', content: 'Here are tourist places nearby Kakkadampoyil, Nilambur to discover nature,s green packed mountains & swirling trees and the river jingles. Few are Adyanpara Waterfall, Conolly Plot, Kozhippara Waterfall, Teak Museum etc.'},
          { property: 'og:title', content: 'Kakkadampoyil Tourist Places'},
          { property: 'og:url', content: 'https://www.mayaahi.com/tourist-places-kakkadampoyil' },
        ]
      }
    }
  },
  {
    path: 'activity',component:ActivityComponent,
    data: {
      seo: {
        title: 'Activities to do in Mayaahi Boutique Stay Kakkadampoyil, Nilambur',
        metaTags: [
          { name: 'description', content: 'Enjoy some of the activities to do at Mayaahi Boutique Stay like explore a Farm, Off - Road drive, Fishing, etc in Kakkadampoyil Nilambur Calicut Malappuram .'},
          { property: 'og:title', content: 'Activities to do in Mayaahi Boutique Stay Kakkadampoyil, Nilambur'},
          { property: 'og:url', content: 'https://www.mayaahi.com/activity' },
        ]
      }
    }
  },
  {
    path: 'resort-with-swimmingpool',component:FacilityComponent,
    data: {
      seo: {
        title: 'Facilities | Mayaahi Resort in Kakkadampoyil,Nilambur',
        metaTags: [
          { name: 'description', content: 'Kakkadampoyil resort with river view private swimming pool and restaurant that surrounds you with everything you need in Nilambur, Malappuram, Calicut.          '},
          { property: 'og:title', content: 'Resort with swimming pool Kakkadampoyil Nilambur Calicut Malappuram'},
          { property: 'og:url', content: 'https://www.mayaahi.com/resort-with-swimmingpool' },
        ]
      }
    }
  },
  {
    path: 'contact',component:ContactComponent,
    data: {
      seo: {
        title: 'Contact | Mayaahi Resort in Kakkadampoyi, Nilambur',
        metaTags: [
          { name: 'description', content: '"Mayaahi resort Kakkadampoyil provides best service at affordable price. Contact us for booking . Contact number: +91 9400142209'},
          { property: 'og:title', content: 'Kakkadampoyil Resort Contact Number'},
          { property: 'og:url', content: 'https://www.mayaahi.com/contact' },
        ]
      }
    }
  },
  { path: 'booknow', component: BooknowComponent },
  { path: 'activity', component: ActivityComponent },
  { path: 'blogs', component: BlogsComponent },
  { path: 'blog-detail', component: BlogDetailComponent },
  { path: 'blogs/:id', component: BlogDetailComponent },
  { path: 'blog-detail-one', component: BlogDetailOneComponent },
  { path: 'blog-detail-two', component: BlogDetailTwoComponent },
  { path: 'testimonials', component: TestimonialDetailComponent},
  { path: 'thank-you', component: ThankYouComponent },
  {path: '**', component:NotFoundComponentComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
