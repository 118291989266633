import '@angular/compiler';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SeoService } from './providers/seo.service';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mayaahi-web';
  lat = 51.678418;
  lng = 7.809007;

  constructor(public gtag: Gtag,private router:Router,public seoService:SeoService,private activatedRoute: ActivatedRoute){


    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      let seoData = data['seo'];
      console.log(seoData)
      if(seoData && seoData['title']){
      this.seoService.updateTitle(seoData['title']);
      }
      if(seoData && seoData['metaTags']){
      this.seoService.updateMetaTags(seoData['metaTags']);
      }
     
    });

  }

  ngOnInit(): void {

  }

  sendAnalatics(){
    this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd) {
       console.log(event.urlAfterRedirects)
  
        this.gtag.pageview({ 
          page_title: event.urlAfterRedirects,
          page_path: this.router.url,
          // page_location: 'https://angularfirebase.com/lessons'
        });
      }
    });
  }
}
