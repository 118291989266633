<app-header></app-header>
<article class="main">
    <section class="page-header">
        <div class="container">
            <!-- <span class="page-subtitle">Leave the rest to the experts</span> -->
            <h1 class="page-title">Rooms</h1>
        </div>
    </section>
    <section class="room-book">
        <div class="container">
            <form class="room-form" [formGroup]="bookingForm">
                <div class="row">
                    <div class="col-lg-10">
                        <div class="form-row">
                            <div class="form-group">
                                <!-- <svg-icon src="assets/img/calendar.svg"></svg-icon> -->
                                <mat-form-field appearance="legacy">
                                    <mat-label>Check In</mat-label>
                                    <input matInput [matDatepicker]="chIn" formControlName="checkIn" (dateChange)="selectCheckInDate($event)" [min]="minDate">
                                    <mat-datepicker-toggle matSuffix [for]="chIn"></mat-datepicker-toggle>
                                    <mat-datepicker #chIn></mat-datepicker>
                                </mat-form-field>
                                <div class="error_div" *ngIf="submitted && avf.checkIn.errors">
                                    <div class="form-error-msg" *ngIf="avf.checkIn.errors.required">*Date is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- <svg-icon src="assets/img/calendar.svg"></svg-icon> -->
                                <mat-form-field appearance="legacy">
                                    <mat-label>Check Out</mat-label>
                                    <input matInput [matDatepicker]="chOut" formControlName="checkout" (dateChange)="selectCheckOutDate($event)" [min]="minDate2">
                                    <mat-datepicker-toggle matSuffix [for]="chOut"></mat-datepicker-toggle>
                                    <mat-datepicker #chOut></mat-datepicker>
                                </mat-form-field>
                                <div class="error_div" *ngIf="submitted && avf.checkout.errors">
                                    <div class="form-error-msg" *ngIf="avf.checkout.errors.required">*Date is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <svg-icon src="assets/img/user.svg"></svg-icon>
                                <mat-form-field appearance="legacy">
                                    <mat-label>Adults</mat-label>
                                    <mat-select formControlName="adults">
                                        <mat-option value="1">1</mat-option>
                                        <mat-option value="2">2</mat-option>
                                        <mat-option value="3">3</mat-option>
                                        <mat-option value="3">4</mat-option>
                                        <mat-option value="4">5</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="error_div" *ngIf="submitted && avf.adults.errors">
                                    <div class="form-error-msg" *ngIf="avf.adults.errors.required">*Adults Count is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <svg-icon src="assets/img/user.svg"></svg-icon>
                                <mat-form-field appearance="legacy">
                                    <mat-label>Children</mat-label>
                                    <mat-select formControlName="children">
                                        <mat-option value="0">0</mat-option>
                                        <mat-option value="1">1</mat-option>
                                        <mat-option value="2">2</mat-option>
                                        <mat-option value="3">3</mat-option>
                                        <mat-option value="3">4</mat-option>
                                        <mat-option value="4">5</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="error_div" *ngIf="submitted && avf.children.errors">
                                    <div class="form-error-msg" *ngIf="avf.children.errors.required">*Childrens Count is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <svg-icon src="assets/img/moon.svg"></svg-icon>
                                <mat-form-field appearance="legacy">
                                    <mat-label>Nights</mat-label>
                                    <input formControlName="nights" type="text" matInput>
                                </mat-form-field>
                                <div class="error_div" *ngIf="submitted && avf.nights.errors">
                                    <div class="form-error-msg" *ngIf="avf.nights.errors.required">*Nights Count is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <input type="reset" class="reset" style="display: none;">
                        <button class="clear-btn clearForm">Clear<svg-icon src="assets/img/close.svg"></svg-icon></button>
                    </div>
                </div>
            </form>
        </div>
    </section>
    <section class="rooms-block">
        <div class="container">
            <div class="row room-wrap">
                <div class="col-lg-4">
                    <div class="room_image_flx">
                        <div class="room_image_inner">
                            <img data-id="" src="assets/img/room-one2.webp" alt="Pool Room">
                        </div>
                        <div class="room_image_small">

                            <img src="assets/img/room-one2.webp" alt="Pool Room">
                            <img src="assets/img/room-one3.webp" alt="Pool Room">
                            <img src="assets/img/room-one4.webp" alt="Pool Room">
                            <img src="assets/img/room-one.webp" alt="Pool Room">
                            <img src="assets/img/room-one6.webp" alt="Pool Room">
                            <img src="assets/img/room-one1.webp" alt="Pool Room">
                            <img src="assets/img/room-one5.webp" alt="Pool Room">
                        </div>
                    </div>

                </div>
                <div class="col-lg-8">
                    <div class="room-data">
                        <a class="room-title" href="#">Private Plunge Pool Room</a>
                        <p class="room-text">Own your space in our Private Pool Room and live within it as every need of yours will be taken care off. Fluffy beds to rest your head along with breathable and comfortable linen. Side tables to assist your surplus needs. Soft
                            rugs to cater your feet. A desk with ample light to nullify the writer's block in you. Wooden wardrobe with generous space. An armchair for a pacified mind. An open pantry for a quick sip and bite. The squeezed oil colours
                            from the painter's palette is patiently and creatively transformed to a masterpiece that will evoke an aura in you. Glass walls with open windows to interconnect with nature. Bathe while adoring the unblocked view of greenery
                            in front of you, and cherish a splash of pristine water in our Private Open Air Plunge Pool alongside Earth's greenery and river stream. The <span ><a class="ex-link" href="https://en.wikipedia.org/wiki/Flora" target="_blank">Flora</a></span> will soothe your mind, while the water refreshes your body.
                            There are so  many <span><a class="ex-link" routerLinkActive="active" routerLink="/activity">Activities</a></span> and so much to explore in Mayaahi.
                        </p>
                        <div class="row mg_t room_idr">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <ul class="room-list">
                                            <li>
                                                <svg-icon src="assets/img/pool.svg"></svg-icon><span>Private Plunge Pool</span>
                                            </li>
                                            <li>
                                                <svg-icon src="assets/img/fridge.svg"></svg-icon><span>Pantry</span>
                                            </li>
                                            <li>
                                                <svg-icon src="assets/img/mountain.svg"></svg-icon><span>River &
                                                    Mountain
                                                    View</span>
                                            </li>
                                            <li>
                                                <svg-icon src="assets/img/home.svg"></svg-icon><span>Home made Personal
                                                    Care
                                                    Products</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 tl" *ngIf="roomPrice.pool_room_price > 0">
                                        <span class="room-price">₹ {{roomPrice.pool_room_price | number: '1.2-2'}}</span>
                                    </div>
                                    <div class="col-md-6 tr">
                                        <a class="room-btn" (click)="MakeBooking('pool_room')">
                                            Book a stay
                                            <svg-icon src="assets/img/arrow-right.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row room-wrap">
                <div class="col-lg-4">
                    <div class="room_image_flx">
                        <div class="room_image_inner">
                            <img src="assets/img/room-two.webp" alt="Pool Room">
                        </div>
                        <div class="room_image_small">
                            <img src="assets/img/room-two.webp" alt="Pool Room">
                            <img src="assets/img/room-two1.webp" alt="Pool Room">
                            <img src="assets/img/room-two2.webp" alt="Pool Room">
                            <img src="assets/img/room-two6.webp" alt="Pool Room">
                            <img src="assets/img/room-two3.webp" alt="Pool Room">
                            <img src="assets/img/room-two5.webp" alt="Pool Room">
                            <img src="assets/img/room-two4.webp" alt="Pool Room">
                        </div>
                    </div>
                    <!-- <owl-carousel-o [options]="multiOptions">
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-two.jpg" alt="Pool Room"></i>
                        </ng-template>
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-two1.jpg" alt="Pool Room"></i>
                        </ng-template>
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-two2.jpg" alt="Pool Room"></i>
                        </ng-template>
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-two3.jpg" alt="Pool Room"></i>
                        </ng-template>
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-two4.jpg" alt="Pool Room"></i>
                        </ng-template>
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-two5.jpg" alt="Pool Room"></i>
                        </ng-template>
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-two6.jpg" alt="Pool Room"></i>
                        </ng-template>
                    </owl-carousel-o> -->
                </div>
                <div class="col-lg-8">
                    <div class="room-data">
                        <a class="room-title" href="#">River View Room</a>
                        <p class="room-text">In Mayaahi you won't be staying but living. River would be your best mate here. You can own your space and live within it as every need of yours will be taken care off. Fluffy beds to rest your head along with breathable and comfortable
                            linen. Side tables to assist your surplus needs. Soft rugs to cater your feet. A desk with ample light to nullify the writer's block in you. Wooden wardrobe with generous space. An armchair for a pacified mind. An open pantry
                            for a quick sip and bite. The squeezed oil colours from the painter's palette is patiently and creatively transformed to a masterpiece that will evoke an aura in you. A true artist's work could be witnessed on your wall. Our
                            bathroom aids you with pristine water that composes your mind for a blissful bath. This space is not completely enclosed, as there are glass walls with open windows to interconnect with the surrounding hills and river!
                        </p>

                        <div class="row mg_t room_idr">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <ul class="room-list">
                                            <li>
                                                <svg-icon src="assets/img/fridge.svg"></svg-icon><span>Pantry</span>
                                            </li>
                                            <li>
                                                <svg-icon src="assets/img/mountain.svg"></svg-icon><span>River &
                                                    Mountain
                                                    View</span>
                                            </li>
                                            <li>
                                                <svg-icon src="assets/img/home.svg"></svg-icon><span>Home made Personal
                                                    Care
                                                    Products</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 tl" *ngIf="roomPrice.river_view_room_price > 0">
                                        <span class="room-price">₹ {{roomPrice.river_view_room_price | number: '1.2-2'}}</span>
                                    </div>
                                    <div class="col-md-6 tr">

                                        <a class="room-btn" (click)="MakeBooking('river_front_room')">
                                            Book a stay
                                            <svg-icon src="assets/img/arrow-right.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="row room-wrap">
                <div class="col-lg-4">
                    <div class="room_image_flx">
                        <div class="room_image_inner">
                            <img src="assets/img/room-three.webp" alt="Pool Room">
                        </div>
                        <div class="room_image_small">
                            <img src="assets/img/room-three.webp" alt="Pool Room">
                            <img src="assets/img/room-three1.webp" alt="Pool Room">
                            <img src="assets/img/lounge.webp" alt="Pool Room">
                            <img src="assets/img/room-three2.webp" alt="Pool Room">
                            <img src="assets/img/room-three3.webp" alt="Pool Room">
                        </div>
                    </div>

                    <!-- <owl-carousel-o [options]="multiOptions">
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-three.jpg" alt="Dormitory"></i>
                        </ng-template>
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-three1.jpg" alt="Dormitory"></i>
                        </ng-template>
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-three2.jpg" alt="Dormitory"></i>
                        </ng-template>
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-three3.jpg" alt="Dormitory"></i>
                        </ng-template>
                        <ng-template carouselSlide>
                            <i class="room-img"><img src="assets/img/room-three4.jpg" alt="Dormitory"></i>
                        </ng-template>
                    </owl-carousel-o> -->
                </div>
                <div class="col-lg-8">
                    <div class="room-data">
                        <a class="room-title" href="#">Executive Dormitory</a>
                        <p class="room-text">Twelve beds beautifully laid out with utmost care and love along with personal lockers, our Air-Conditioned Dormitory is the right place to spend time with your gang of loved ones! Each bed space is designed with individual lighting
                            and electric socket to cater your personal needs. Hang out by chattering and playing games in our lounge area of wooden seating & comforting cushions accompanied by plenty of ventilation, books, cards and many more. Our Dormitory
                            also gifts you a balcony wherein a cup of warm tea and a good company can ameliorate any exhausting of life! Any space of comfort would be void without the luxury of a heavenly bath, hence immerse yourself in good thoughts
                            as you bathe in our spacious bathroom!
                        </p>

                        <div class="row mg_t room_idr">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <ul class="room-list">
                                            <li>
                                                <svg-icon src="assets/img/dining.svg"></svg-icon><span>Dining
                                                    Room</span>
                                            </li>
                                            <li>
                                                <svg-icon src="assets/img/living.svg"></svg-icon><span>Living
                                                    Lounge</span>
                                            </li>
                                            <li>
                                                <svg-icon src="assets/img/balcony.svg"></svg-icon><span>Balcony</span>
                                            </li>
                                            <li>
                                                <svg-icon src="assets/img/home.svg"></svg-icon><span>Home made Personal
                                                    Care
                                                    Products</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 tl" *ngIf="roomPrice.doormitory_room_price > 0">
                                        <span class="room-price">₹ {{roomPrice.doormitory_room_price | number: '1.2-2'}}</span>
                                    </div>
                                    <div class="col-md-6 tr">
                                        <a class="room-btn" (click)="MakeBooking('dormitory')">
                                            Book a stay
                                            <svg-icon src="assets/img/arrow-right.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</article>


<div class="modl_main" style="display: none;">
    <div class="md_inner">
        <img src="assets/img/close.svg" class="close_mdl">
        <div class="md_main_image">
            <img alt="Pool Room" data-id="" class="mdl_big_img">
            <div class="nav_mdl">
                <img src="assets/img/arr.svg" class="next_mdl">
                <img src="assets/img/arr.svg" class="prev_mdl">
            </div>
        </div>
        <div class="mdn_child_image">

        </div>
    </div>
</div>


<app-footer></app-footer>
