<app-header></app-header>
<article class="main">
    <section class="page-header">
        <div class="container">
            <span class="page-subtitle">Leave the rest to the experts</span>
            <h1 class="page-title">Rooms</h1>
        </div>
    </section>
    <section class="room-book">
        <div class="container">
          <form class="room-form">
              <div class="row">
                  <div class="col-lg-10">
                    <div class="form-row">
                        <div class="form-group">
                            <svg-icon src="assets/img/calendar.svg"></svg-icon>
                            <mat-form-field appearance="legacy">
                                <mat-label>Check In</mat-label>
                                <input matInput [matDatepicker]="chIn">
                                <mat-datepicker-toggle matSuffix [for]="chIn"></mat-datepicker-toggle>
                                <mat-datepicker #chIn></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <svg-icon src="assets/img/calendar.svg"></svg-icon>
                            <mat-form-field appearance="legacy">
                                <mat-label>Check Out</mat-label>
                                <input matInput [matDatepicker]="chOut">
                                <mat-datepicker-toggle matSuffix [for]="chOut"></mat-datepicker-toggle>
                                <mat-datepicker #chOut></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <svg-icon src="assets/img/user.svg"></svg-icon>
                            <mat-form-field appearance="legacy">
                                <mat-label>Adults</mat-label>
                                <mat-select>
                                    <mat-option value="1">1</mat-option>
                                    <mat-option value="2">2</mat-option>
                                    <mat-option value="3">3</mat-option>
                                    <mat-option value="3">4</mat-option>
                                    <mat-option value="4">5</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <svg-icon src="assets/img/user.svg"></svg-icon>
                            <mat-form-field appearance="legacy">
                                <mat-label>Children</mat-label>
                                <mat-select>
                                    <mat-option value="1">1</mat-option>
                                    <mat-option value="2">2</mat-option>
                                    <mat-option value="3">3</mat-option>
                                    <mat-option value="3">4</mat-option>
                                    <mat-option value="4">5</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <svg-icon src="assets/img/moon.svg"></svg-icon>
                            <mat-form-field appearance="legacy">
                                <mat-label>Nights</mat-label>
                                <mat-select>
                                    <mat-option value="1">1</mat-option>
                                    <mat-option value="2">2</mat-option>
                                    <mat-option value="3">3</mat-option>
                                    <mat-option value="3">4</mat-option>
                                    <mat-option value="4">5</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                      <button class="clear-btn">Clear<svg-icon src="assets/img/close.svg"></svg-icon></button>
                  </div>
              </div>
          </form>
        </div>
    </section>
    <section class="book-block">
        <div class="container">
            <form class="book-form">
                <h6 class="form-title">Personal Details</h6>
                <div class="row mb-5">
                    <div class="col-lg-4">
                        <input class="form-input" type="text" name="name" id="name" placeholder="Your Name">
                    </div>
                    <div class="col-lg-4">
                        <input class="form-input" type="phone" name="phone" id="phone" placeholder="Phone Number">
                    </div>
                    <div class="col-lg-4">
                        <input class="form-input" type="email" name="email" id="email" placeholder="Email Address">
                    </div>
                </div>
                <h6 class="form-title">Additional Information</h6>
                <div class="row mb-5">
                    <div class="col-lg-4">
                        <input class="form-input" value="10:00" type="time" name="time" id="name" placeholder="Time of Arrival">
                        <div class="agree-col">
                            <input type="checkbox" name="agree" id="agree">
                            <label for="agree">I Agree with the Booking Rules</label>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <textarea class="form-input" name="comments" id="comments" placeholder="Comments"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <button class="form-btn" type="submit">Make Booking<svg-icon src="assets/img/arrow-right.svg"></svg-icon></button>
                    </div>
                </div>
            </form>
        </div>
    </section>
</article>
<app-footer></app-footer>