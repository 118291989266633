import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss']
})
export class FacilityComponent implements OnInit {

  content=[
    {
      image:['assets/img/Pool-Three-.webp','assets/img/Pool-1.webp','assets/img/Exterior-3-.webp','assets/img/Pool-Mayaahi-.webp'],
      title: "River View Infinity Pool",
      main: "Reconnect with the goodness of aqua in our own River View Infinity Pool, nestled amid the green. An Infinity Pool overlooking the river awaits you for a perfect swim under the star.",
      description: " Revive in our Infinity Pool for an unparalleled view of the mountain where sun drenched blue skies and azure river stretch out in every direction. To swim under the sky embracing the mountains and river is truly enchanting. While you gaze back at our Infinity Pool you feel the impulse to dive in again as our Pool, brush stroked with the essence of blue could make your day! Enjoy our River View Infinity Pool with a backdrop of endless beauty for the ultimate indulgence."
    },
    {
      image:['assets/img/Exterior.webp','assets/img/Inner-5.webp','assets/img/inner-chair-.webp','assets/img/Roohi-4.webp','assets/img/Outer-Cusion-.webp','assets/img/exterior-chair-2-.webp','assets/img/Exterior-Chair-.webp','assets/img/Kitchen-.webp','assets/img/Interior.webp'],
      title: 'Roohi Art Cafe',
      main: 'One cannot think well if he has not dined well hence revive your spirit at Roohi Art Cafe. We serve tantalizing cuisine with breathtaking mountain and river views. An immersive dining experience to excite your senses. Roohi Art Cafe combines an exhilarating array of mouth-watering flavours and textures.',
      description: 'We source high-quality fresh produce and we’re proud to serve you ingredients endemic to Kerala. An Art Cafe where thoughtful cooking and nourishing food come together. The comfort that comes out from eating a hot plate of food while cracking old jokes is always a mere pleasure of joy. Enjoy dining where genuine hospitality and guest experience takes precedence. Pull up a chair and relish Mayaahi cuisine as life is endlessly delicious.'
    },
    // {
    //   image:['assets/img/fl3.jpg','assets/img/budha.jpg'],
    //   title: 'Tribal Budha',
    //   main: "The millions of encounters our river water have shared with memories of each of our guests is countless.",
    //   description: "When we look beyond materialistic creations, we are able to behold who we truly are and that's why we would love to welcome you to our Tribal Budha! Read, sing, relax, write, observe, paint, you could do anything here, you could joyfully immerse in the beauty of the flowing river, you could plug in your headset and wander through emotions of imaginations, it's a space for everyone to meet your inner needs! We have wooden seating and vibrant hammocks so that you can sit back and enjoy the sights of nature. "
    // },
    // {
    //   image:['assets/img/fl4.jpg'],
    //   title: 'Reception',
    //   main: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem    aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae explicabo.',
    //   description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.'
    // },
    {
      image:['assets/img/Lounge-2-.webp','assets/img/Lounge-room-.webp'],
      title: 'Lounge Room',
      main: "Lighten up and give rest to the pressures weighing you down at Mayaahi's Lounge Room. Read, eat, sing or dream as to get in touch with yourself is a necessity to live your life. Indulge with your gang of loved one's by playing board games, chattering, listening to music at our Lounge Room. ",
      description:"Breathe fresh air in this aerated space and relax yourself on our wooden seating accompanied by comfortable cushions. Time passes fast, therefore make time to treat yourself with cheerfulness and peace. Make your stay at Mayaahi more reviving by devoting time at our Lounge Room. "
    },
    {
      image:['assets/img/Candle-light-Dinner-.webp'],
      title: 'Candlelight Dinner',
      main: "An intimate dinner to glimmer your connection with your loved one.",
      description: "Candles burning brightly with the prettiest table settings of cotton napkins, flowers, silverware and food. All worries vanish when you indulge in a Candlelight Dinner of your heart - warming food with your best mate. This captivating dinner is an art that enchants your mood as food not only keeps your body together but also your soul. Relish and quench your desired food and drinks with your beloved one as Mayaahi's Candlelight Dinner can set the mood and pace of your whole day. "
    },
    {
      image:['assets/img/f16.webp'],
      title: 'Ping - Pong!',
      main: "Paddle in your hand and the sound of the ball, Table Tennis is always amusing! This remarkable speed game is so versatile that even a beginner can still have a good time. ",
      description: "You could play Table Tennis to ease your mind, or you could play for scores to bring out your competitive essence. Table Tennis is all about the rhythm of strokes as it is faster, the ball is lighter thus making your mind sharper. Our Ping - Pong room is also a hub to have fun for people of all ages! Showcase your personal flair and enjoy the Ping - Pong session at Mayaahi! "
    },
    {
      image:['assets/img/Hall-3.webp'],
      title: 'A Walk-through Art',
      main: "Art is one of the richness of our graceful life, as it's created from heart. A painting for each one would open different doors of meaning and traverse yours at our Art Gallery. The loveliness of each brush strokes could be felt as you walk through our Gallery. ",
      description: "Not just the walls, look up, even the ceiling greets you with a painting! Our Gallery has the power to mesmerise you and lift your mind as each of the painting could carry you away for a long time. Every Art portrayed in this Gallery is also the fervor of our artist's. Witness a collection of blank canvases aesthetically altered to colours of harmony! "
    },

    {
      image:['assets/img/Hall-2-.webp','assets/img/Hall-4.webp','assets/img/Hall-2-.webp','assets/img/Hall-1.webp',],
      title: 'Banquet Hall',
      main: "Parties, corporate events, wedding feasts, you name it, we can host it. Our artistically inspired Banquet Hall is geared for big gatherings and events. Our Banquet Hall facing the river would be a perfect setting for your aspired occasion as it’s one of the most elegant and modern.  ",
      description: "We’re available throughout the year and open for pre-booked guided tours. With a flawless blend of art and dedicated service, our Banquet Hall is equipped with adequate seating, a podium, audio settings, a projector, customisable amenities and catering service. Do not hesitate for events of more than one day, as we have accommodation facilities with enthralling views accompanied by plentiful dishes to cater for your palate. We invite you to our Banquet Hall to unfold your desired event. "
    },
     
     
  ]

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  multiOptions: OwlOptions = {
    items: 1,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 0,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    nav: false
  }

}
