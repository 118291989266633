<body>
    <section class="logo_sec">
        <img src="assets/img/footer-logo.png" alt="Mayaahi">
    </section>
    <section class="thank_sec">
        <div class="container">
            <div class="row">
                <h2 class="thank_title">Thank You!</h2>
                <p class="thank_sub">Successfully submitted your booking request. We will contact you soon</p>

            </div>
        </div>
    </section>
    <section class="btn">
        <button class="back_btn"><a routerLinkActive="active" routerLink="/rooms-homestays">GO BACK</a></button>
    </section>
</body>
