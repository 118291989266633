<header class="header">
    <nav class="navbar">
        <a class="navbar-logo" routerLink=""><img src="assets/img/logo.png" alt="Mayaahi"></a>
        <button type="button" class="navbar-toggler" (click)="is_show=!is_show" [ngClass]="{'show':is_show}">
      <span class="navbar-icon"></span>
    </button>
        <div class="navbar-collapse" [ngClass]="{'show':is_show}">
            <ul class="navbar-nav">
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="">Home</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/best-resort-kakkadampoyil">About Us</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/rooms-homestays">Rooms</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/activity">Activities</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/resort-with-swimmingpool">Facilities</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/tourist-places-kakkadampoyil">Neighbourhood</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/blogs">Blogs</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/testimonials">Testimonials</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/contact">Contact Us</a></li>
            </ul>
        </div>
    </nav>
</header>