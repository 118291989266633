import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/providers/common.service';
import { EventPublishService } from 'src/app/providers/event-publish.service';
import { BookingService } from '../../providers/booking.service';
import SweetAlert from 'sweetalert';
import * as $ from 'jquery';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  contactForm: any = FormGroup;
  validator = new Validators;
  submitted: boolean = false;
  bookNow:boolean = false;
  minDate:any;
  minDate2:any;
  currentYear: number = new Date().getFullYear();

  constructor(public formBuilder: FormBuilder, public booking: BookingService,public common:CommonService,public events:EventPublishService,
    public datePipe:DatePipe,public router:Router) {
   this.events.getObservable().subscribe((data)=>{
     if(data =='form_data_updated'){
       let that=this
      setTimeout(function(){
        that.contactForm.get('checkin').patchValue(that.common.bookingData.check_in);
        that.contactForm.get('checkout').patchValue(that.common.bookingData.check_out);
        that.contactForm.get('adults').patchValue(that.common.bookingData.adults);
        that.contactForm.get('child').patchValue(that.common.bookingData.childrens);
        that.contactForm.get('room_id').patchValue(that.common.bookingData.room_id);
        that.contactForm.get('nights').patchValue(that.common.bookingData.nights);
      },200);
     }
   })
  }

  ngOnInit(): void {

    this.contactForm = this.formBuilder.group({
      checkin: ['', Validators.required],
      checkout: ['', Validators.required],
      adults: ['', Validators.required],
      child: [''],
      customer_name: ['', Validators.required],
      customer_email: ['', Validators.required],
      customer_mobile: ['', Validators.required],
      room_id: [''],
      customer_address: ['', Validators.required],
      nights:['',Validators.required]
    })

    var y:any=this.datePipe.transform(Date(),'yyyy')
    var m:any=this.datePipe.transform(Date(),'MM')
    console.log(m)
    var d:any=this.datePipe.transform(Date(),'dd')
    this.minDate = new Date(y, m-1, d);
    console.log( this.minDate)

    $(".modal_booking_close").click(function(e){
      // $(".book-now").removeClass("ModalBooking");
    });
  }

  scrollTop() {
    window.scroll(0, 0);
  }
  get acf() { return this.contactForm.controls; }


  bookRoom() {
    console.log("kkkkkkkkkkkkkkkk")
    this.bookNow = true;
    this.submitted = true

    if (this.contactForm.valid) {
      console.log("kkkkkkkkkkkkkkkk")
      var data = {
        customer_name: this.acf.customer_name.value,
        customer_email: this.acf.customer_email.value,
        customer_mobile: this.acf.customer_mobile.value,
        room_id: this.acf.room_id.value,
        customer_address: this.acf.customer_address.value,
        check_in:this.datePipe.transform(this.acf.checkin.value,'yyyy-MM-dd') ,
        check_out:this.datePipe.transform(this.acf.checkout.value,'yyyy-MM-dd')  ,
        childrens: this.acf.child.value,
        adults: this.acf.adults.value
      }
      console.log(data)
      this.booking.bookRoom(data).subscribe(resp => {
        if (resp.success) {
          this.router.navigate(['/thank-you'])
          console.log(resp)

        }
        else{
          this.common.bookNow=false
            this.router.navigate(['/rooms-homestays'])
        }
      })
    }

    this.common.bookingData={
      check_in:this.acf.checkin.value,
      check_out:this.acf.checkout.value,
      adults:this.acf.adults.value,
      childrens:this.acf.child.value
    }

    this.router.navigate(['/rooms-homestays'])
  }

  bookNowModal(){
    this.bookNow = true;
  }
  closeModal(){
    this.bookNow = false;
    this.common.bookNow = false;
  }

  selectCheckInDate(event:any){
    this.contactForm.get('checkin').setValue(event.value);
    console.log( this.contactForm.value.checkIn)
    var y:any=this.datePipe.transform(this.contactForm.value.checkin,'yyyy')
    var m:any=this.datePipe.transform(this.contactForm.value.checkin,'M')
    var d:any=this.datePipe.transform(this.contactForm.value.checkin,'dd')
    this.minDate2=new Date(y, m-1, d);

  }
}
