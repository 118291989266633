import { Component, OnInit } from '@angular/core';
import { BlogsService } from 'src/app/providers/blogs.service';


@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  blogList: any = []
  p: number = 1;

  constructor(public blogService: BlogsService) { }

  ngOnInit(): void {
    this.getblogList()

  }

  async getblogList() {
    await this.blogService.getAllBlogs().subscribe(
      (response) => {
        this.blogList = response.data?.data;
      },
      (error) => {
        console.error(error); 
      }
    );
  }
  

}
