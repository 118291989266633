import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  bookNow:boolean=false
  bookingData:any=[]
  FORM_DATA_UPDATED="form_data_updated"
}
