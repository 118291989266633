
<footer class="footer">
    <a class="whats-app" href="https://wa.me/919400142209" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>
    </a>
    <div class="container">
        <a (click)="scrollTop()" class="scroll-top">
            <svg-icon src="assets/img/arrow-up.svg"></svg-icon>
        </a>
        <div class="row">
            <div class="col-lg-12 text-center">
                <h4 class="footer-title lg">Get in Touch!</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <a class="footer-logo" href="/"><img src="assets/img/footer-logo.png" alt="Mayaahi"></a>
                <span class="footer-title">Get in touch with us</span>
                <ul class="footer-list">
                    <li>
                        <svg-icon src="assets/img/map.svg"></svg-icon><span>Mayaahi Boutique Stay, 6th Block, Edivanna, Kakkadampoyil, Nilambur, Kerala, 679329.</span></li>
                    <li>
                        <svg-icon src="assets/img/phone.svg"></svg-icon><a href="tel:+918281698402">+91 82816 98402</a>, <a href="tel:+919400142209">+91 94001 42209</a></li>
                    <li>
                        <svg-icon src="assets/img/mail.svg"></svg-icon><a href="#">info@mayaahi.com	</a></li>
                </ul>
                <ul class="footer-social">
                    <li>
                        <a href="https://www.facebook.com/Mayaaahi/" target="_blank">
                            <svg-icon src="assets/img/facebook.svg"></svg-icon>
                        </a>
                    </li>
                    <li>
                        <a href="https://instagram.com/mayaahi_" target="_blank">
                            <svg-icon src="assets/img/instagram.svg"></svg-icon>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCy2g7fPmE-v5XpXqreoA2Dw" target="_blank">
                            <svg-icon src="assets/img/youtube.svg"></svg-icon>
                        </a>
                    </li>
                </ul>
                <!-- <span class="footer-title primary">Subscribe to Newsletter</span>
                <form class="newsletter-form">
                    <input type="email" placeholder="Enter your email here..." id="email" name="email" required>
                    <button type="submit"><svg-icon src="assets/img/arrow-right.svg"></svg-icon></button>
                </form> -->

            </div>
            <div class="col-lg-8">
                <div class="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3911.9802224509313!2d76.14539901533622!3d11.33615505171085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba63fca2d4849c1%3A0x6daca96ad34191be!2sMayaahi!5e0!3m2!1sen!2sin!4v1609819291369!5m2!1sen!2sin"
                        frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="book-now" [ngClass]="{'ModalBooking': common.bookNow==true}">
                    <h3 class="book-title">Excited to Host you!</h3>
                    <img src="assets/img/close.svg" class="modal_booking_close" (click)="closeModal()">
                    <form class="book-form" [formGroup]="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <input type="text" class="input_1" placeholder="Name" formControlName="customer_name">
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <input type="text" class="input_1" placeholder="Email" formControlName="customer_email">
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <input type="text" class="input_1" placeholder="Mobile" formControlName="customer_mobile">
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <select class="input_1" formControlName="room_id">
                                    <option selected disabled>Select Room</option>
                                    <option value="pool_room">Pool Room</option>
                                </select>
                                <!-- <input type="text" class="input_1" placeholder="Room"> -->
                            </div>
                            <div class="col-lg-12">
                                <textarea placeholder="Address" formControlName="customer_address" class="input_1"></textarea>
                            </div>
                            <div class="col-lg-12"></div>
                            <div class="col-lg-3 col-sm-6">
                                <mat-form-field appearance="legacy">
                                    <mat-label>Check In</mat-label>
                                    <input formControlName="checkin" matInput [matDatepicker]="chIn" (dateChange)="selectCheckInDate($event)" [min]="minDate">
                                    <mat-datepicker-toggle matSuffix [for]="chIn"></mat-datepicker-toggle>
                                    <mat-datepicker #chIn></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <mat-form-field appearance="legacy">
                                    <mat-label>Check Out</mat-label>
                                    <input formControlName="checkout" matInput [matDatepicker]="chOut" [min]="minDate2">
                                    <mat-datepicker-toggle matSuffix [for]="chOut"></mat-datepicker-toggle>
                                    <mat-datepicker #chOut></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <mat-form-field appearance="legacy">
                                    <mat-label>Adults</mat-label>
                                    <mat-select formControlName="adults">
                                        <mat-option value="1">1</mat-option>
                                        <mat-option value="2">2</mat-option>
                                        <mat-option value="3">3</mat-option>
                                        <mat-option value="3">4</mat-option>
                                        <mat-option value="4">5</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <mat-form-field appearance="legacy">
                                    <mat-label>Children</mat-label>
                                    <mat-select formControlName="child">
                                        <mat-option value="0">0</mat-option>
                                        <mat-option value="1">1</mat-option>
                                        <mat-option value="2">2</mat-option>
                                        <mat-option value="3">3</mat-option>
                                        <mat-option value="3">4</mat-option>
                                        <mat-option value="4">5</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <!-- <div class="col-lg-2 col-sm-6">
                                <mat-form-field appearance="legacy">
                                    <mat-label>Nights</mat-label>
                                    <input formControlName="nights" type="text" matInput>
                                </mat-form-field>
                            </div> -->
                            <div class="col-lg-12 text-center">
                                <button type="submit" (click)="bookRoom()">
                                    Make a booking
                                    <svg-icon src="assets/img/arrow-right.svg"></svg-icon>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="row mt50">
            <div class="col-md-6 tl">
                <span class="copyright">© {{currentYear}} Mayaahi</span>
            </div>
            <div class="col-md-6 tr">
                <span class="copyright">Powered By
                    <a href="https://eximuz.com/home" target="_blank">
                        Eximuz Technolabs
                    </a>
                </span>
            </div>
        </div>
    </div>
</footer>

<!--
<div class="footerMdl" *ngIf="" (click)="closeModal()">
    <div class="inner_modal book-now">
        <div class="innre_header">

        </div>
        <div class="mdl_form">
            <form>
                <input type="text" class="input_1" placeholder="Name">
                <input type="text" class="input_1" placeholder="Email">
                <input type="text" class="input_1" placeholder="Mobile">
                <input type="text" class="input_1" placeholder="Room">
                <textarea placeholder="Address" class="input_1"></textarea>
                <button type="submit" (click)="bookRoom()">
                    Make Booking
                    <svg-icon src="assets/img/arrow-right.svg"></svg-icon>
                </button>
            </form>
        </div>
    </div>
</div> -->
