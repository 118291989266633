<app-header></app-header>
<section class="page-header">
  <div class="container">
    <!-- <span class="page-subtitle">indulge your adventurous spirit</span> -->
    <h1 class="page-title">Testimonials</h1>
  </div>
</section>
<section class="testimonials">
<div class="container">
  <div class="testimonial-grid">
    <div *ngFor="let testimonial of testimonials" class="testimonial-card">
      <div class="testimonial-image">
        <ng-container *ngIf="testimonial.url; else image">
          <!-- Show video iframe if videoUrl is present -->
          <div class="video-container">
            <iframe *ngIf="testimonial?.url" width="560" height="315" src="testimonial?.url"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
        </ng-container>
        <ng-template #image>
          <!-- Show image if videoUrl is not present -->
          <img [src]="testimonial.image_url + testimonial.photo" alt="Banner Image">
        </ng-template>
      </div>
      <div class="testimonial-content">
        <p [innerHTML]="testimonial.testimonial" [hidden]="testimonial.showFullContent">
          {{ testimonial.testimonial | slice: 0:200 }}{{ testimonial.testimonial.length > 200 ? '...' : '' }}
          <a href="#" (click)="showFullTestimonial($event, testimonial)">Read More</a>
        </p>
        <p [hidden]="!testimonial.showFullContent">
          {{ testimonial.testimonial }}
          <a href="#" (click)="showLessTestimonial($event, testimonial)">Show Less</a>
        </p>
        <span class="testimonial-author">{{ testimonial?.name }}</span>
        <span class="testimonial-desg">{{ testimonial?.designation }}</span>
      </div>
    </div>
  </div>
</div>
</section>








<app-footer></app-footer>