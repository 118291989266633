import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
import { TestimonialsService } from 'src/app/providers/testimonials.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  videobg: boolean = true;
  isLoading: boolean = true
  bnrBg:boolean = true;
  public testimonials: any =[]
  public formattedData: any = []

  url:any;
  orderby:any;

  schema = {
    "@context": "https://schema.org",
"@type": "LocalBusiness",
"name": "Mayaahi Boutique stay",
"image": "https://mayaahi.com/assets/img/logo.png",
"@id": "",
"url": "https://mayaahi.com/",
"telephone": "8281698402, 9400142209",
"address": {
"@type": "PostalAddress",
"streetAddress": "6th Block, Edivanna, Kakkadampoyi",
"addressLocality": "Nilambur, Kerala",
"postalCode": "679329",
"addressCountry": "IN"
},
"geo": {
"@type": "GeoCoordinates",
"latitude": 11.317168,
"longitude": 76.1993509
},
"openingHoursSpecification": {
"@type": "OpeningHoursSpecification",
"dayOfWeek": [
"Monday",
"Tuesday",
"Wednesday",
"Thursday",
"Friday",
"Saturday",
"Sunday"
],
"opens": "00:00",
"closes": "23:59"
},
"sameAs": [
"https://www.facebook.com/Mayaaahi/",
"https://www.instagram.com/mayaahi_/",
"https://www.youtube.com/channel/UCy2g7fPmE-v5XpXqreoA2Dw"
]
  };


  constructor(public route:ActivatedRoute, private testimonialsService:TestimonialsService,private _router: Router,) {
    this.route.queryParams.subscribe((params:any)=>{
      this.orderby=params.orderby
      this._router.navigate([ '' ],)
    })
   }
    //


  ngOnInit(): void {
    window.scroll(0, 0);
    // $(".video_frame").click(function(){
    //   $(".homeframe").attr("autoplay", "1");
    //   $(this).hide();
    //  });
    this.getTestimonialsList();
    this.testimonials

  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoading = false;
      this.bnrBg = false;
    }, 1000);
  }


  facilityOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 14,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  }

  eventOptions: OwlOptions = {
    items: 1,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 0,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    nav: false
  }

  showIframe(e: any) {
    console.log(e)
    this.videobg = false;
  }

  
    resetScrollAndRedirect(): void {
      window.scrollTo(0, 0);
      this._router.navigate(['/testimonials']);
    }

    async getTestimonialsList() {
      await this.testimonialsService.getAllTestimonials().subscribe(
        (response) => {
          this.testimonials = response.data.data;
  
          this.formattedData = this.testimonials.slice(0,3)
          console.log("test",this.testimonials.slice(0,3))
        },
        (error) => {
          console.error(error); 
        }
      );
    }
  



}


