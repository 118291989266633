<app-header></app-header>
<article class="main">
    <section class="page-header">
        <div class="container">
            <!-- <span class="page-subtitle">indulge your adventurous spirit</span> -->
            <h1 class="page-title">Activities</h1>
        </div>
    </section>
    <article class="facilit-wrap">
        <section class="facilit" *ngFor="let cnt of content; let i=index" id="{{cnt.id}}">
            <div class="container grid_base_a" *ngIf="i%2==0" >
                <div class="row" [id]="cnt.id">
                    <div class="col-lg-6">
                        <div class="facilit__content">
                            <h2 class="facilit__title">{{cnt.title}}</h2>
                            <h6 class="facilit__sub">{{cnt.main}}</h6>
                            <p class="facilit__text">{{cnt.description}}</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="facilit__slider">
                            <owl-carousel-o [options]="multiOptions">
                                <ng-template carouselSlide *ngFor="let i of cnt.image">
                                  <i class="image"><img src="{{i}}" alt="pool"></i>
                                </ng-template>
                              </owl-carousel-o>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container grid_base_b" *ngIf="i%2 !=0" >
                <div class="row">
                    <div class="col-lg-6">
                        <div class="facilit__slider">
                            <owl-carousel-o [options]="multiOptions">
                                <ng-template carouselSlide *ngFor="let i of cnt.image">
                                  <i class="image"><img src="{{i}}" alt="pool"></i>
                                </ng-template>
                              </owl-carousel-o>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="facilit__content right" >
                            <h2 class="facilit__title">{{cnt.title}}</h2>
                            <h6 class="facilit__sub">{{cnt.main}}</h6>
                            <p class="facilit__text">{{cnt.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
        <section class="niambur_sec">
            <div class="container grid_base" >
                <div class="row">
                    <div class="col-lg-6">
                        <div class="facilit__slider">
                            <owl-carousel-o [options]="multiOptions">
                                <ng-template carouselSlide >
                                  <i class="image"><img src="assets/img/Road-1.webp" alt="pool"></i>
                                </ng-template>
                                <ng-template carouselSlide >
                                    <i class="image"><img src="assets/img/Nilambur -two.webp" alt="pool"></i>
                                  </ng-template>
                                  <ng-template carouselSlide >
                                    <i class="image"><img src="assets/img/Nilambur-3.webp" alt="pool"></i>
                                  </ng-template>
                                  <ng-template carouselSlide >
                                    <i class="image"><img src="assets/img/Hanginng-bridge-.webp" alt="pool"></i>
                                  </ng-template>
                              </owl-carousel-o>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="facilit__content right" >
                            <h2 class="facilit__title">Touring Nilambur</h2>
                            <h6 class="facilit__sub">No trip is interesting if you didn't get to see what you have always wanted to. Close to the Nilgiris on the banks of Chaliyar, Nilambur is a town that must be experienced! This most photographed city of Malappuram, beholds many iconic trademarks of Kerala.</h6>
                            <p class="facilit__text">Sightsee with Mayaahi as we take in the landmarks of this <span><a class="ex-link" routerLinkActive="active" routerLink="/tourist-places-kakkadampoyil">Neighbourhood</a></span>, hemmed by teak trees on either side. On this sightseeing journey of Nilambur which is surrounded by dense forest of mixed vegetation and rivers, it would be a miss if you didn't visit the
                                 <span><a class="ex-link" href="https://en.wikipedia.org/wiki/Teak_Museum" target="_blank">World's first Teak Museum</a></span>. Conolly's Plot, the first man made teak plantation in the world is also a prominent destination and the list continues. Nilambur is not just about historic sites and serene beauty, local dish such as the lip - smacking beef curry and crisp porottas are also a delight to your heart!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>

</article>
<app-footer></app-footer>