import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './views/home/home.component';

import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AboutComponent } from './views/about/about.component';
import { RoomComponent } from './views/room/room.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FacilityComponent } from './views/facility/facility.component';
import { BooknowComponent } from './views/booknow/booknow.component';
import { ContactComponent } from './views/contact/contact.component';
import { ActivityComponent } from './views/activity/activity.component';

import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import { NeighbourhoodComponent } from './views/neighbourhood/neighbourhood.component';
import { HttpModule } from '@angular/http';
import { DatePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlogsComponent } from './views/blogs/blogs.component';
import { ThankYouComponent } from './views/thank-you/thank-you.component';
import { NotFoundComponentComponent } from './views/not-found-component/not-found-component.component';
import { BlogDetailComponent } from './views/blog-detail/blog-detail.component';
import { BlogDetailOneComponent } from './views/blog-detail-one/blog-detail-one.component';
import { BlogDetailTwoComponent } from './views/blog-detail-two/blog-detail-two.component';
import { TestimonialDetailComponent } from './views/testimonial-detail/testimonial-detail.component';
import { GtagModule } from 'angular-gtag';








@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    RoomComponent,
    FacilityComponent,
    BooknowComponent,
    ContactComponent,
    ActivityComponent,
    NeighbourhoodComponent,
    BlogsComponent,
    ThankYouComponent,
    NotFoundComponentComponent,
    BlogDetailComponent,
    BlogDetailOneComponent,
    BlogDetailTwoComponent,
    TestimonialDetailComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    BrowserAnimationsModule,
    CarouselModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatButtonModule,
    GtagModule.forRoot({ trackingId: 'G-7H6BZJL9CD', trackPageviews: true }),
    MatSelectModule,
    NgxPaginationModule,
    HttpModule
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
