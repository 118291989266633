import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(public api:ApiService) { }


  bookRoom(data:any){
   let endpoint='roommboking'
   return this
   .api
   .post(endpoint,data)
   .pipe(map(resp=>resp.json()))
  }
}
