<app-header></app-header>
<article class="main">
    <section class="page-header">
        <div class="container">
            <!-- <span class="page-subtitle">Creating your perfect world</span> -->
            <h1 class="page-title">Mayaahi</h1>
        </div>
    </section>
    <section class="about-intro">
        <div class="container">
            <h3 class="about-title"> Who are we?</h3>
            <h6 class="intro-text">Mayaahi is where we come from, it's an open book, wherein you can drop in anytime & relish each page. If you believe nature is a place to visit, think again, mother earth is home & Mayaahi is our humble abode. Each of us has our own metaphors
                of life, for us Mayaahi is a hue of vibrancy wherein petals rustles as the wind refreshes. We all share the same air and that's why Mayaahi welcomes you.
            </h6>
        </div>
    </section>
    <section class="about-one">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="about-col pr">
                        <h3 class="about-title"> What can we gift you?</h3>
                        <p class="about-text">Your journey towards us will begin to float your mind on a magical carpet ride through nature's green packed mountains & swirling trees and as the river jingles, you arrive. Located at Kakkadampoyil, Nilambur, Kerala, Mayaahi beholds
                            the warmth of green and gifts you services that are not just mere assistance but an experience that would fulfil your soul. As you enter our home, you encounter chirping birds, wavy petals, bloomed flowers, dew dropped leaves
                            and smiling faces, all ready to greet you with open arms. The yard paved with brick's and meadow leads your path towards your shelter and that's not it. Few steps more, you enter a mystic heaven blended with water, rocks and
                            trees. The river is your best mate here, she will be always next to you, to guard you, to rejuvenate you, along with us. When you replay over and over the time you spend at Mayaahi, eventually they turn into the essence of
                            you. Each guests who leave Mayaahi, imprints an impact through grace and beauty on us.
                        </p>

                    </div>
                </div>
                <div class="col-lg-6">
                    <i class="about-img right"><img src="assets/img/about-one.webp" alt=""></i>
                </div>
            </div>
        </div>
    </section>
    <section class="about-one pd_sm">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <i class="about-img"><img src="assets/img/about-two.webp" alt=""></i>
                </div>
                <div class="col-lg-6">
                    <div class="about-col pl">

                        <p class="about-text">An in drawn breath of Kakkadampoyil is Mayaahi's element as it's sited on the <span><a class="ex-link" href="https://en.wikipedia.org/wiki/Western_Ghats" target="_blank">Western Ghats</a></span> among higher altitudes. 
                            Earth's creations never pulls down each other therefore they coexist hence <span><a class="ex-link" routerLinkActive="active" routerLink="/activity">Mayaahi</a></span> is blessed to be a segment of
                            Earth's beloved Kuruvana River. As the sun sets, the profound blue backdrop of Mayaahi melts away into a dazzling star's comfortable abode. As Kakkadampoyil's wind runs over Mayaahi's river your stay with us becomes more gratifying.
                            Look up first and greet the sky that Kakkadampoyil beholds. As soil and air binds this Universe, Mayaahi paints your eternal fantasy.</p>

                        <i class="about-img small"><img src="assets/img/about-three.webp" alt=""></i>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- <section class="about-one">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <p class="about-text w90"></p>
                </div>
            </div>
        </div>
    </section> -->
    <!-- <section class="about-one pd_b">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="about-col pr">
                        <span class="about-text lg">Our Promise</span>
                        <h3 class="about-title">At One with Nature</h3>
                        <p class="about-text">We don't build buildings, but rather we build settings where families can create intergenerational memories and enrich their lives in exquisite and untouched sanctuaries. Everything we do is driven by the fundamental desire to
                            create spaces where families can be together.</p>
                        <p class="about-text">These settings offer a relaxed and comfortable sense of community through outstanding phenomenal ambience, hiking, off road ride, farm visit, fishing and cultural activities. All offering the highest of service standards to enrich
                            your experience.</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <i class="about-img right"><img src="assets/img/about-four.webp" alt=""></i>
                </div>
            </div>
        </div>
    </section> -->
</article>
<app-footer></app-footer>