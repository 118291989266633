import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-neighbourhood',
  templateUrl: './neighbourhood.component.html',
  styleUrls: ['./neighbourhood.component.scss']
})
export class NeighbourhoodComponent implements OnInit {

  content=[
    // {
    //   image:['assets/img/Waterfalls-2-.jpg'],
    //   km:'16',
    //   title: 'Adyanpara Waterfall',
    //   main: "Run wild and free like Nilambur's Adyanpara Waterfall! The rhythm of this waterfall is a lullaby for your heart.",
    //   description: "This eminent site belongs to the village of Kurumbalangode. Adyanpara Waterfall is a delight, principally during monsoon. This waterfall is not only presented by clear pristine water, it's also adjoined by green lushly forest. The water is even reckoned to have medicinal properties. The water descends over rocks, making Adyanpara Waterfall more notable for trekking. You might even encounter migratory birds as this is one of their treasured hubs! "
    // },
    {
      image:['assets/img/Forest-.webp'],
      km:'20',
      title: "Conolly's Plot",
      main: "Cross the longest hanging bridge of Kerala across Chaliyar river and Kurunjipuzha and witness this colossal beauty! Honored after H.V Conolly, the district collector during the rule of British, Conolly's Plot is one of the oldest man-made teak plantations in the world. ",
      description: "H.V Conolly's decision to plant teaks gave life to this celebrated teak plantation. Conolly's Plot even takes care, one of the oldest living teak trees that has a girth of 420 cm. Take a walk through this promised land of teaks, and you might even be greeted by rare and exotic birds! "
    },
    {
      image:['assets/img/Waterfalls.webp'],
      km:'10',
      title: 'Kozhippara Waterfall',
      main: "This dazzling waterfall is situated in a serene setting of flora. It's close to the borders of Malappuram and Calicut district, embracing the mountain ranges of Western Ghats. Possessed to the Kuthradampuzha River, Kozhippara Waterfall is an escape from the bustles of the city. ",
      description: "It's not just about watching and learning the water, it's about the feel Kozhippara Waterfalls gifts you. This rhythmic waterfall's cool and crystal-clear water not only pacifies your eyes but also your mind."
    },
    {
      image:['assets/img/Neighbour-.webp'],
      km:'16',
      title: 'Teak Museum',
      main:"Who wouldn't want to drop in to the world's one and only Teak Museum? This museum would take you into the behind the scenes of Teak Trees. ",
      description: "You would not only get to know more about these marvelous trees, but also you come across traditional artefacts such as household objects, cloth chest that are carved from teak. Teak Museum accommodates a bio resource park where butterflies and rare exotic plants are taken care of. This is also home to a collection of infrequent moths, insects and butterflies. Teak Museum enlightens you about cultivation, habitat and management of teaks, so make sure to not miss it! "
    },
    // {
    //   image:['assets/img/calicut-one.jpg','assets/img/calicut-two.jpg','assets/img/calicut-three.jpg','assets/img/calicut-four.jpg'],
    //   km:'55',
    //   title: 'Kozhikode (Calicut)',
    //   main: 'Kozhikode also known as Calicut, is a city in the state of Kerala in southern India on the Malabar coast. Kozhikode is the largest urban area in the state and 195th largest urban area in the world.',
    //   description: "H.V Conolly's decision to plant teaks gave life to this celebrated teak plantation. Conolly's Plot even takes care, one of the oldest living teak trees that has a girth of 420 cm. Take a walk through this promised land of teaks, and you might even be greeted by rare and exotic birds! "
    // },
    // {
    //   image:['assets/img/wayanad-one.jpg','assets/img/wayanad-two.jpg','assets/img/wayanad-three.jpg','assets/img/wayanad-four.jpg'],
    //   km:'82',
    //   title: 'Wayanad',
    //   main: 'Wayanad, the green paradise is nestled among the mountains of the Western Ghats, forming the border world of the greener part of Kerala. Clean and pristine, enchanting and hypnotizing, this land is filled with history and culture. Located at a distance of 76 km.',
    //   description: 'Regarding geographical location, Wayanad district stands on the southern top of the Deccan plateau and its chief glory is the majestic Western Ghats with lofty ridges and rugged terrain interspersed with dense forest, tangled jungles and deep valleys. The place also enjoys a strategic location as the leading tourist centres of South India like Ooty, Mysore, Bangalore, Coorg and Kannur are situated around this region.'
    // },
   
    // {
    //   image:['assets/img/nilambur-one.jpg','assets/img/nilambur-two.jpg','assets/img/nilambur-three.jpg','assets/img/nilambur-four.jpg'],
    //   km:'16',
    //   title: 'Nilambur',
    //   main: 'Nilambur is a major town, a municipality and a Taluk in the Malappuram district of the Indian state of Kerala. It is located close to the Nilgiris range of the Western Ghats on the banks of the Chaliyar River. This place is also known as `Teak Town` because of the abundance of Nilambur teaks in this area.',
    //   description: 'Which is a variety of a large, deciduous tree that occurs in mixed hardwood forests. Unlike many Municipalities of India; Nilambur is covered with large amounts of vegetation making it close to nature and hence make it very scenic and inspiring. In the British records, Nilambur and its surroundings are collectively described as Nilambur Valley. The bank of river Chaliyar at Nilambur region is also known for natural Gold fields. Explorations done at the valley of the river Chaliyar in Nilambur has shown reserves of the order of 2.5 million cubic meters of placers with 0.1 gram per cubic meter of gold.'
    // }
  ]


  multiOptions: OwlOptions = {
    items: 1,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 0,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    nav: false
  }

  constructor() { }

  ngOnInit(): void {
  }

}
