<app-header></app-header>

<head>

    <meta name="twitter:card" content="summary">
    <meta name="twitter:site" content="@Mayaahi">
    <meta name="twitter:title" content="Resorts in Kakkadampoyil: Discover Mayaahi Boutique Stay">
    <meta name="twitter:description"
        content="Resorts in Kakkadampoyil introduce Mayaahi Boutique Stay, your ideal retreat in Kerala's enchanting landscapes. Experience unparalleled luxury, authentic cuisine, and engaging activities in a serene s">
    <meta name="twitter:image" content="https://mayaahi.com/assets/img/about-one.webp">
    <meta property="og:title" content="Resort in Kakkadampoyil | Mayaahi Boutique Stay">
    <meta property="og:site_name" content="Mayaahi">
    <meta property="og:url" content="">
    <meta property="og:description" content="">
    <meta property="og:type" content="restaurant">
    <meta property="og:image" content="https://www.mayaahi.com/assets/img/about-one.webp">

    <script type="application/ld+json">
            {
              "@context": "http://schema.org", 
              "@type": "Hotel",
              "name": "Mayaahi Boutique Stay | Rain Forest Resort in Kakkadampoyil, Nilambur",
              "alternateName": "Mayaahi Boutique Stay",
              "url": "https://www.mayaahi.com/",
              "logo": "https://www.mayaahi.com/assets/img/logo.png",
              "image": "https://www.mayaahi.com/assets/img/pool-2.webp",
              "description": "Mayaahi - Boutique Stay in Kakkadampoyil, situated in the captivating town of Kakkadampoyil, Nilambur. Immerse yourself in the charm and serenity of this picturesque destination, where the wonders of nature seamlessly blend with refined elegance. Indulge in ultimate comfort and impeccable service, ensuring an unforgettable experience throughout your stay. It is the Best resort in Kakkadampoyil, Nilambur",
              "sameAs": [
                    "https://www.facebook.com/Mayaaahi/",
                    "https://www.instagram.com/mayaahi_/"
                    ],
              "telephone": "+91 82816 98402",
              "email": "info@mayaahi.com",
              "hasMap": "https://goo.gl/maps/35Q6i8eB5mLYm1XdA",
              "checkinTime": "14:00",
              "checkoutTime": "11:00",
              "additionalType": "https://en.wikipedia.org/wiki/Boutique_hotel",
               "areaServed": "Kerala",
               "address" :  {
                "@type" : "PostalAddress",
                "streetAddress": "6th Block, Edivanna",
                "addressLocality": "Kakkadampoyil",
                "addressRegion": "Nilambur",
                "postalCode": "679329",
                 "addressCountry": "India"
              },
              "priceRange": "Best Rate Guaranteed Direct",
              "starRating": { 
                "@type" : "http://schema.org/Rating",
                "ratingValue": "5" 
              },
               "amenityFeature": [
                {"@type":"LocationFeatureSpecification","name":"Free Wi-Fi"},
                {"@type":"LocationFeatureSpecification","name":"Free parking"},
                {"@type":"LocationFeatureSpecification","name":"Accessible"},
                {"@type":"LocationFeatureSpecification","name":"Air-conditioned"},
                {"@type":"LocationFeatureSpecification","name":"Pool"},
                {"@type":"LocationFeatureSpecification","name":"Child-friendly"},
                {"@type":"LocationFeatureSpecification","name":"Restaurant"}
              ],
        
           
               "result":{
               "@type":"LodgingReservation",
               "name":"Book Now"
              }
            
}
            </script>

    <script type="application/ld+json">
        {
        "@context": "http://schema.org", 
        "@type": "Hotel",
        "name": "Resorts in Kakkadampoyil: Mayaahi Boutique Stay – A Unique Luxury Experience",
        "alternateName": "Mayaahi Boutique Stay",
        "url": "https://www.mayaahi.com/",
        "logo": "https://mayaahi.com/assets/img/logo.png",
        "image": "https://mayaahi.com/assets/img/room-one.webp",
        "description": "Resorts in Kakkadampoyil: Discover luxury at Mayaahi Boutique Stay. Elegant accommodations and serene beauty for a perfect tranquil retreat.",
        "sameAs": [
              "https://www.facebook.com/Mayaaahi/",
              "https://www.instagram.com/mayaahi_/"
              ],
        "telephone": "+91 82816 98402",
        "email": "info@mayaahi.com",
        "hasMap": "https://goo.gl/maps/35Q6i8eB5mLYm1XdA",
        "checkinTime": "14:00",
        "checkoutTime": "11:00",
        "additionalType": "https://en.wikipedia.org/wiki/Boutique_hotel",
         "areaServed": "Kerala",
         "address" :  {
          "@type" : "PostalAddress",
          "streetAddress": "6th Block, Edivanna",
          "addressLocality": "Kakkadampoyil",
          "addressRegion": "Nilambur",
          "postalCode": "679329",
           "addressCountry": "India"
        },
        "priceRange": "Best Rate Guaranteed Direct",
        "starRating": { 
          "@type" : "http://schema.org/Rating",
          "ratingValue": "5" 
        },
         "amenityFeature": [
          {"@type":"LocationFeatureSpecification","name":"Free Wi-Fi"},
          {"@type":"LocationFeatureSpecification","name":"Free parking"},
          {"@type":"LocationFeatureSpecification","name":"Accessible"},
          {"@type":"LocationFeatureSpecification","name":"Air-conditioned"},
          {"@type":"LocationFeatureSpecification","name":"Pool"},
          {"@type":"LocationFeatureSpecification","name":"Child-friendly"},
          {"@type":"LocationFeatureSpecification","name":"Restaurant"}
        ],
        "result":{
            "@type":"LodgingReservation",
            "name":"Book Now"
           }
    
    
    }
        </script>

</head>
<article class="main">
    <section class="banner">
        <i class="image">
            <video playsinline autoplay muted loop id="bannervideo" oncanplay="this.play()"
                onloadedmetadata="this.muted = true">
                <source src="assets/img/banner.mp4" type="video/mp4">
            </video>
        </i>
        <div class="content">
            <h1 class="title">The Sky, The Hills & Mayaahi.</h1>
            <a class="button" href="/rooms-homestays">
                Book a Stay!
                <svg-icon src="assets/img/arrow-right.svg"></svg-icon>
            </a>
            <a class="scroll" [routerLink]="['/']" fragment="introBlock">
                <svg-icon src="assets/img/arrow-down.svg"></svg-icon>
            </a>
        </div>
    </section>
    <section class="h-intro" id="introBlock">
        <div class="container">
            <h2 class="intro-title">Mayaahi</h2>
            <p class="intro-text">Mayaahi is an experience brought together by the earth, water, sky and a bunch of
                enlightened beings. Our Boutique Stay's sky sails along with a bundle of our guest's tales. When every
                flower returns to sleep, Mayaahi beams like a lantern.
                The caress of our Boutique Stay's refreshing air will kindle your heart.
            </p>
            <div class="intro-video">
                <i class="img">
                    <img src="assets/img/videobg.webp" class="video_frame" alt="Mayaahi Experience Video" *ngIf="videobg" (click)="showIframe($event)">
                    <iframe class="homeframe" width="800" height="450" src="https://www.youtube.com/embed/xJG2Csu1LRw"
                        title="Mayaahi Experience Video" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        loading="lazy" allowfullscreen></iframe>

                </i>
            </div>
        </div>
    </section>
    <section class="rooms">
        <div class="container">
            <div class="section-head">
                <span class="sub">Stay</span>
                <h3 class="title">Root yourself within us. </h3>
            </div>
            <div class="rooms-wrapper">
                <div class="rooms-col" routerLink="/rooms-homestays">
                    <i class="image"><img src="assets/img/room-one.webp" alt="Pool Room"></i>
                    <div class="content">
                        <a class="title">Private Plunge Pool Room</a>
                        <p class="text">A River Front Room with Private Pool that captivates nature's essence.</p>
                        <!-- <span class="price">₹ 8000.00</span> -->
                    </div>
                </div>
                <div class="rooms-col" routerLink="/rooms-homestays">
                    <i class="image"><img src="assets/img/Resorts-in-Kakkadampoyil-with-River-view-rooms.webp" alt="River Front Room"></i>
                    <div class="content">
                        <a class="title">River View Room</a>
                        <p class="text">Embrace Earth and soothe your mind & body at our River View Room.
                        </p>
                        <!-- <span class="price">₹ 4500.00</span> -->
                    </div>
                </div>
                <div class="rooms-col" routerLink="/rooms-homestays">
                    <i class="image"><img src="assets/img/Executive-Dorms.webp" alt="Dormitory"></i>
                    <div class="content">
                        <a class="title">Executive Dormitory</a>
                        <p class="text">An air conditioned twelve bed Dormitory with a lounge room and balcony.</p>
                        <!-- <span class="price">₹ 1250.00</span> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="activity">
        <div class="container">
            <div class="section-head">
                <span class="sub">Activities</span>
                <h3 class="title">More than a stay.</h3>
            </div>
            <div class="row">
                <div class="col-lg-3 empty">
                    <div class="activity-col empty"></div>
                </div>
                <div class="col-lg-3 col-sm-6 pointer" [routerLink]="['/activity']" fragment="off-road">
                    <div class="activity-col big">
                        <i class="image"><img src="assets/img/Offroad-one-.webp" alt="Off Road"></i>
                        <h3 class="act-title mob">Off - Roading</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6  pointer" [routerLink]="['/activity']" fragment="farm">
                    <div class="activity-col">
                        <i class="image"><img src="assets/img/Farm1.webp" alt="Farm Visit"></i>
                        <h3 class="act-title mob">Explore Farm</h3>
                    </div>
                </div>
                <div class="col-lg-3 empty">
                    <div class="activity-col empty"></div>
                </div>
                <div class="col-lg-3 empty  pointer" [routerLink]="['/activity']" fragment="farm">
                    <div class="activity-col empty">
                        <h3 class="act-title">Explore Farm</h3>
                    </div>
                </div>
                <div class="col-lg-3 empty">
                    <div class="activity-col empty"></div>
                </div>
                <div class="col-lg-3 col-sm-6 pointer" [routerLink]="['/activity']" fragment="hike">
                    <div class="activity-col">
                        <i class="image"><img src="assets/img/Hiking2.webp" alt="Farm Visit"></i>
                        <h3 class="act-title mob">Hiking</h3>
                    </div>
                    <h3 class="act-title lft">Hiking</h3>
                </div>
                <div class="col-lg-3 empty pointer" [routerLink]="['/activity']" fragment="off">
                    <div class="activity-col empty">
                        <h3 class="act-title">Off - Roading</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 pointer" [routerLink]="['/activity']" fragment="nila">
                    <div class="activity-col big">
                        <i class="image"><img src="assets/img/Nilambur -two.webp" alt="Off Road"></i>
                        <h3 class="act-title mob">Touring Nilambur</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 pointer" [routerLink]="['/activity']" fragment="fish">
                    <div class="activity-col">
                        <i class="image"><img src="assets/img/Fishing-2.webp" alt="Farm Visit"></i>
                        <h3 class="act-title mob">Fishing</h3>
                    </div>
                </div>
                <div class="col-lg-3 empty pointer" [routerLink]="['/activity']" fragment="fish">
                    <div class="activity-col empty">
                        <h3 class="act-title">Fishing</h3>
                    </div>
                </div>
                <div class="col-lg-3 empty">
                    <div class="activity-col empty"></div>
                </div>
                <div class="col-lg-3 col-sm-6 pointer" [routerLink]="['/activity']" fragment="dish">
                    <div class="activity-col">
                        <i class="image"><img src="assets/img/Food-.webp" alt="Farm Visit"></i>
                        <h3 class="act-title mob">Learn a Dish</h3>
                    </div>
                </div>
                <div class="col-lg-3 empty pointer" [routerLink]="['/activity']" fragment="nila">
                    <div class="activity-col empty">
                        <h3 class="act-title">Touring Nilambur</h3>
                    </div>
                </div>
                <div class="col-lg-3 empty">
                    <div class="activity-col empty">
                    </div>
                </div>
                <div class="col-lg-3 empty">
                    <div class="activity-col empty" style="height: auto;">
                    </div>
                </div>
                <div class="col-lg-3 empty pointer" [routerLink]="['/activity']" fragment="dish">
                    <div class="activity-col empty" style="height: auto;">
                        <h3 class="act-title lft">Learn a Dish</h3>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-sm-6">
                    <div class="activity-col">
                        <i class="image"><img src="assets/img/act6.jpg" alt="Farm Visit"></i>
                        <h3 class="act-title mob">Cultural Activities</h3>
                    </div>
                    <h3 class="act-title lft">Cultural Activities</h3>
                </div> -->
            </div>
        </div>
    </section>
    <section class="facility">
        <div class="container">
            <div class="section-head">
                <span class="sub">Facilities</span>
                <h3 class="title">Enhance your stay merrily.</h3>
            </div>
            <div class="facility-slider">
                <owl-carousel-o [options]="facilityOptions">
                    <!-- <ng-template carouselSlide>
                        <div class="item">
                            <i class="image"><img src="assets/img/fl4.jpg" alt="reception"></i>
                            <a class="title" >Reception</a>
                        </div>
                    </ng-template> -->
                    <ng-template carouselSlide>
                        <div class="item">
                            <i class="image"><img src="assets/img/Lounge-2-.webp" alt="lounge"></i>
                            <a class="title">Lounge</a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <i class="image"><img src="assets/img/Candle-light-Dinner-.webp" alt="candle light dinner"></i>
                            <a class="title">Candle Light Dinner</a>
                        </div>
                    </ng-template>
                    <!-- <ng-template carouselSlide>
                        <div class="item">
                            <i class="image"><img src="assets/img/fl7.jpg" alt="pool board"></i>
                            <a class="title" >Pool Board</a>
                        </div>
                    </ng-template> -->
                    <ng-template carouselSlide>
                        <div class="item">
                            <i class="image"><img src="assets/img/f16.webp" alt="table tennis"></i>
                            <a class="title">Ping - Pong!</a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <i class="image"><img src="assets/img/Pool-Three-.webp" alt="pool"></i>
                            <a class="title">Plunge Pool</a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <i class="image"><img src="assets/img/Exterior.webp" alt="restaurant"></i>
                            <a class="title">Roohi</a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <i class="image"><img src="assets/img/River-view-.webp" alt="tribal budha"></i>
                            <a class="title">Tribal Budha</a>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <i class="image"><img src="assets/img/Hall-2-.webp" alt="banquet-hall"></i>
                            <a class="title">Banquet Hall</a>
                        </div>
                    </ng-template>

                </owl-carousel-o>
            </div>
        </div>
    </section>
    <section class="rooms">
        <div class="container">
            <div class="section-head">
                <span class="sub">Testimonials</span>
                <h3 class="title">The Voice of Our Delighted Guests</h3>
            </div>
            <div class="rooms-wrapper" >
                <div class="rooms-col" *ngFor="let data of formattedData" (click)="resetScrollAndRedirect()">
                    <i class="image"><img [src]="data.image_url + data.photo" alt="Testimonials"></i>
                    <div class="content testimonial-wrapper">
                        <p class="text testimonial-text" [innerHTML]="data?.testimonial">{{data?.testimonial}}</p>
                        <span class="testimonial-author">{{ data?.name }}</span>
                        <span class="testimonial-desg">{{ data?.designation }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="parallax">
        <div class="content">
            <span class="content__meta">Neighbourhood</span>
            <h2 class="content__title">Go around & drop by nature's delights. </h2>
            <a class="content__btn" routerLink="/tourist-places-kakkadampoyil">
                Explore More
                <svg-icon src="assets/img/arrow-right.svg"></svg-icon>
            </a>
        </div>
    </section>

    <section class="kakkadampoyil_sec">
        <div class="container">
            <div class="section-head">
                <h3 class="title1">Kakkadampoyil</h3>
            </div>
            <div class="para">
                <p class="kakkadampoyil_para">Anyone who’s come across Kakkadampoyil would agree that it’s a pristine
                    sanctuary in nature.
                    The canvas upon which Kakkadampoyil’s serenity is written is itself astonishing.
                    Positioned on the western tops of the western ghats with altitudes ranging from 700ft to 1200ft,
                    Kakkadampoyil is truly a delight. A scenic spot for hikers to explore the land of serenity.
                    From lush forests to charming mountains, Kakkadampoyil also hosts incredible waterfalls. 2
                    3 km from Nilambur, this haven isn’t just getting by on its look, there are plenty of activities to
                    get into here like,
                    cycling, fishing, hiking and birdwatching. Kakkadampoyil is one of the tributaries of the Chaliyar
                    River.
                    A holiday here can involve endless days of the alluring sky, pristine river and glorious mountains.
                    Whether you’re a first-timer or a frequent visitor, kakkadampoyil never fails to win your
                    hearts! Discover the sights and sounds of Kakkadampoyil as there’s no better place to unwind.</p>
            </div>
        </div>
    </section>
    <!-- <section class="event">
    <div class="container">
      <div class="section-head">
        <span class="sub">Events</span>
        <h3 class="title">God's Own Heritage</h3>
      </div>
      <div class="event-slider">
        <owl-carousel-o [options]="eventOptions">
          <ng-template carouselSlide>
            <div class="item">
              <div class="item__content">
                <span class="item__content--meta">Upcoming Event</span>
                <h4 class="item__content--title">Christmas Eve 2020</h4>
                <p class="item__content--text">Make Christmas the most wonderful ! Please join us for a holiday celebration. We hope you can join us for our Annual Christmas celebration. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.</p>
                <div class="item__content--icons">
                  <div class="icon">
                    <svg-icon src="assets/img/calendar.svg"></svg-icon>
                    <span class="icon-text">25 December 2020</span>
                    <span class="icon-text">5 : 00 PM</span>
                  </div>
                  <div class="icon">
                    <svg-icon src="assets/img/ticket.svg"></svg-icon>
                    <span class="icon-text">₹ 10000</span>
                    <span class="icon-text">(With Accommodation)</span>
                  </div>
                </div>
                <a class="item__content--btn" href="#">
                  Reserve Now
                  <svg-icon src="assets/img/arrow-right.svg"></svg-icon>
                </a>
              </div>
              <i class="item__image"><img src="assets/img/event1.jpg" alt="christmas"></i>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="item">
              <div class="item__content">
                <span class="item__content--meta">Upcoming Event</span>
                <h4 class="item__content--title">Christmas Eve 2020</h4>
                <p class="item__content--text">Make Christmas the most wonderful ! Please join us for a holiday celebration. We hope you can join us for our Annual Christmas celebration. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.</p>
                <div class="item__content--icons">
                  <div class="icon">
                    <svg-icon src="assets/img/calendar.svg"></svg-icon>
                    <span class="icon-text">25 December 2020</span>
                    <span class="icon-text">5 : 00 PM</span>
                  </div>
                  <div class="icon">
                    <svg-icon src="assets/img/ticket.svg"></svg-icon>
                    <span class="icon-text">₹ 10000</span>
                    <span class="icon-text">(With Accommodation)</span>
                  </div>
                </div>
                <a class="item__content--btn" href="#">
                  Reserve Now
                  <svg-icon src="assets/img/arrow-right.svg"></svg-icon>
                </a>
              </div>
              <i class="item__image"><img src="assets/img/event1.jpg" alt="christmas"></i>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="item">
              <div class="item__content">
                <span class="item__content--meta">Upcoming Event</span>
                <h4 class="item__content--title">Christmas Eve 2020</h4>
                <p class="item__content--text">Make Christmas the most wonderful ! Please join us for a holiday celebration. We hope you can join us for our Annual Christmas celebration. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.</p>
                <div class="item__content--icons">
                  <div class="icon">
                    <svg-icon src="assets/img/calendar.svg"></svg-icon>
                    <span class="icon-text">25 December 2020</span>
                    <span class="icon-text">5 : 00 PM</span>
                  </div>
                  <div class="icon">
                    <svg-icon src="assets/img/ticket.svg"></svg-icon>
                    <span class="icon-text">₹ 10000</span>
                    <span class="icon-text">(With Accommodation)</span>
                  </div>
                </div>
                <a class="item__content--btn" href="#">
                  Reserve Now
                  <svg-icon src="assets/img/arrow-right.svg"></svg-icon>
                </a>
              </div>
              <i class="item__image"><img src="assets/img/event1.jpg" alt="christmas"></i>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </section> -->
</article>
<app-footer></app-footer>

<div class="loading_spinner" *ngIf="isLoading">
    <img src="assets/img/load.svg">
</div>